<template>
  <v-dialog v-model="isOpen" width="1000">
    <v-card>
      <v-card-title class="headline">Review and commit changes</v-card-title>

      <v-card-text>
        <v-container>
          <div class="my-6">Please review your changes and write a descriptive commit message.</div>
          <DiffView :diff="diff" />
          <v-row>
            <v-col>
              <v-textarea v-model="message" spellcheck="true" lang="en" label="Message" rows="1" auto-grow />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Cancel" @click="isOpen = false" />

        <v-btn text="Commit" color="primary" :loading="isLoading" :disabled="message.length < 1" @click="save()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { JsonDiff } from '@jouzen/outo-toolkit-vuetify'

  import { AppStore } from '#stores'

  @Component({})
  class CommitDialog extends mixins(JsonDiff) {
    @Emit('saved')
    public saved(data: any) {
      return data
    }

    @Emit('created')
    public created(data: any) {
      return data
    }

    public diff = ''
    public message = ''

    public isOpen = false
    public isLoading = false

    private path = ''
    private screenshot = ''

    private data: any = {}

    private appStore = new AppStore()

    @Watch('isOpen')
    protected isOpenChanged(open: boolean) {
      if (!open) {
        this.message = ''
      }
    }

    public open(name: string, path: string, data: any, previewPrev: any, previewNext?: any, screenshotData?: any) {
      this.isOpen = true
      this.data = data
      this.path = path
      this.screenshot = screenshotData
      this.diff = this.getDiff(previewPrev, previewNext ?? data, name) || ''
    }

    public async save() {
      this.isLoading = true

      const data = await this.appStore.commitChanges(this.message, this.data, this.path, this.screenshot)

      this.isLoading = false
      this.isOpen = false

      if (!this.data.createdAt) {
        this.created(data)
      }

      this.saved(data)
    }
  }

  export default toNative(CommitDialog)
</script>
