<template>
  <v-data-table
    v-if="namedCriteriasOperator(override)"
    no-data-text="No user label segments selected"
    :headers="accountsHeaders"
    :loading="isLoading"
    :search="usersFilter"
    :hide-default-footer="labelUsers.length <= 10"
    :items="labelUsers"
    :items-per-page="10"
  >
    <template #item.icon="{ item }">
      <v-tooltip
        :text="
          !item.all && !namedCriteriasInverted(override)
            ? 'User is always included in this rollout'
            : 'User is never included in this rollout'
        "
      >
        <template #activator="{ props }">
          <v-icon v-bind="props" :color="!item.all && !namedCriteriasInverted(override) ? 'green' : 'red'">
            {{ !item.all && !namedCriteriasInverted(override) ? 'mdi-check-circle-outline' : 'mdi-cancel' }}
          </v-icon>
        </template>
      </v-tooltip>
    </template>

    <template #item.author="{ item }">
      <span>{{ item.author || 'Not yet implemented' }}</span>
    </template>

    <template #item.state="{ item }">
      <span>
        {{ !item.all && !namedCriteriasInverted(override) ? 'INCLUDED' : 'EXCLUDED' }}
      </span>
    </template>

    <template #item.createdAt="{ item }">
      <span>
        {{ formatDateTime(item.createdAt || Date.now()) }}
      </span>
    </template>

    <template #body.append>
      <tr v-if="labelUsers.length > 0">
        <td>
          <v-icon :color="!labelUsers.length || namedCriteriasInverted(override) ? 'green' : 'red'">
            {{ !labelUsers.length || namedCriteriasInverted(override) ? 'mdi-check-circle-outline' : 'mdi-cancel' }}
          </v-icon>
        </td>
        <td>All {{ labelUsers.length ? 'other ' : '' }}users matching the users targeting criteria</td>
        <td>
          {{ !labelUsers.length || namedCriteriasInverted(override) ? 'INCLUDED' : 'EXCLUDED' }}
        </td>
        <td>USERS_TARGETING_CRITERIA</td>
        <td class="text-right">
          {{
            formatDateTime(
              override?.metadata?.informative?.additionalData?.updatedAt ||
                override?.metadata?.informative?.additionalData?.createdAt ||
                Date.now(),
            )
          }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
  import { unionBy } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { accountsHeaders, cloudEnvs } from '#views/features/constants'

  import { forEachNamedCriteria, namedCriteriasInverted, namedCriteriasOperator } from '#views/features/utilities'

  import { LabelsStore, SegmentsStore } from '#stores'

  import { Feature, Override, RolloutTarget } from '#types'

  @Component({})
  class StatusLabels extends Vue {
    @Prop() public feature!: Feature
    @Prop() public override!: Override

    @Prop() public timeDisplay!: string
    @Prop() public usersFilter!: string

    @Prop() public rolloutView!: RolloutTarget

    public labelUsers: any[] = []

    public readonly accountsHeaders = accountsHeaders

    public readonly namedCriteriasInverted = namedCriteriasInverted
    public readonly namedCriteriasOperator = namedCriteriasOperator

    private readonly labelsStore = new LabelsStore()
    private readonly segmentsStore = new SegmentsStore()

    public get isLoading() {
      return this.labelsStore.waiting
    }

    public get settingsIndex() {
      return this.rolloutView.startsWith('advanced') ? parseInt(this.rolloutView.split('-')[1]) : 0
    }

    public get existingCriterias() {
      return this.segmentsStore.criterias
    }

    @Watch('override', { deep: true, immediate: true })
    protected async onPreviewExperimentITemChanged() {
      if (this.override) {
        let count = 0

        let labelUsers: any[] = []

        // TODO: should try to filter envs for the advanced rollouts!
        // TODO: should go through custom criteria and pickup the user uid's from there as well!

        forEachNamedCriteria(this.override, async (criteriaName) => {
          const criteria = this.existingCriterias.find((c) => c.metadata?.name === criteriaName)

          if (
            criteria &&
            criteria?.expression?.oneOf?.$case === 'predicate' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
          ) {
            const envs = this.rolloutView.startsWith('advanced')
              ? ['test', 'stage', 'prod']
              : [cloudEnvs[this.rolloutView]]

            for (const env of envs) {
              const label = criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.equals

              let users = await this.labelsStore.listLabelUsers(env, label)

              users = users.map((u) => ({ ...u, author: criteria.metadata?.name?.toUpperCase() }))

              if (namedCriteriasOperator(this.override) !== 'or') {
                labelUsers = !count ? users : unionBy(labelUsers, users, (i) => i.userUuid)
              } else {
                labelUsers = labelUsers.concat(users)
              }
            }

            count++
          }

          this.labelUsers = labelUsers
        })
      }
    }

    public formatDateTime(dateTime: string | number) {
      if (this.timeDisplay === 'utc') {
        return this.$dayjs(dateTime).utc().format('HH:mm - DD MMM YYYY UTC')
      } else if (this.timeDisplay === 'detroit') {
        return this.$dayjs(dateTime).tz('America/Detroit').format('HH:mm - DD MMM YYYY z')
      } else if (this.timeDisplay === 'helsinki') {
        return this.$dayjs(dateTime).tz('Europe/Helsinki').format('HH:mm - DD MMM YYYY z')
      } else if (this.timeDisplay === 'relative') {
        const d = this.$dayjs.duration(this.$dayjs(dateTime).diff())

        return (
          (d.milliseconds() > 0 ? 'In ' : d.asMinutes() >= 1 ? '' : 'Less than minute') +
          (['days', 'hours', 'minutes'].reduce((acc, cur) => {
            const value = Math.abs((d as any)[cur]())

            if (value > 0) {
              return `${acc} ${value} ${value === 1 ? cur.slice(-1) : cur}`
            }

            return acc
          }, '') +
            (d.milliseconds() > 0 ? '' : ' ago'))
        )
      } else {
        return this.$dayjs(dateTime).format('HH:mm - DD MMM YYYY z')
      }
    }
  }

  export default toNative(StatusLabels)
</script>
