<template>
  <v-alert v-if="releasing || !!actions.length" type="info" class="my-8">
    There is a
    <a
      target="_blank"
      rel="noopener noreferrer"
      class="text-primary font-weight-bold"
      href="https://github.com/jouzen/insight-engine/actions"
    >
      GitHub release
    </a>
    in progress, the list will update once its completed...

    <template #append>
      <v-btn text="Refresh" color="primary" @click="updateRollouts()" />
    </template>
  </v-alert>

  <v-row>
    <v-col cols="12">
      <v-sheet>
        <v-data-table
          show-expand
          single-expand
          expand-on-click
          disable-pagination
          hide-default-footer
          no-data-text="No app content OTA rollouts"
          :loading="loading"
          :headers="otaHeaders"
          :expanded="expanded"
          :items="rollouts"
          :items-per-page="100"
          style="width: 100%; max-width: 100%; cursor: pointer"
        >
          <template #item.age="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{
                $dayjs(item.created_at)
                  .fromNow(true)
                  .replace('a day', '1 day')
                  .replace('an hour', '1 hour')
                  .replace('a month', '1 month')
              }}
            </span>
          </template>

          <template #item.value.version="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.value.version }}
            </span>
          </template>

          <template #item.release="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.flavor ? rolloutProjectName(item) : 'New production content release' }}
            </span>
          </template>

          <template #item.creator="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{
                (metadata[item.value.version] &&
                  metadata[item.value.version].rollouts &&
                  metadata[item.value.version].rollouts[item.slug] &&
                  metadata[item.value.version].rollouts[item.slug].creator) ||
                'No creator info available'
              }}
            </span>
          </template>

          <template #item.status="{ item }">
            <v-chip
              label
              style="text-transform: capitalize; color: white; min-width: 220px; font-weight: bold"
              :color="rolloutStatusColor(item)"
              :class="isOldVersion(item) ? 'lighten-4' : ''"
            >
              {{
                item.filters.includes('label:criteria:ota-content-private-users')
                  ? 'Private group only'
                  : (item.flavor || 'production') + ' app'
              }}
            </v-chip>
          </template>

          <template #expanded-row="{ item }">
            <td class="expanded-row" :colspan="otaHeaders.length">
              <div class="pl-15 pt-4">
                <div class="d-flex flex-column flex-sm-row mt-4 mb-4 mr-4">
                  <div class="ml-3">
                    <div class="caption">RELEASE INFO</div>

                    <template v-if="!metadata[item.value.version] || !metadata[item.value.version].link">
                      No release info available
                    </template>
                    <template v-else>
                      <a :href="metadata[item.value.version].link" target="_blank">
                        {{ metadata[item.value.version].link }}
                      </a>
                    </template>
                  </div>

                  <v-spacer />

                  <div class="pr-4">
                    <div class="caption mb-6" style="text-align: right">CREATED</div>
                    <b>{{ $dayjs(item.created_at).format('HH:mm DD MMM YYYY') }}</b>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row mt-8 mb-4 mr-4">
                  <v-spacer />

                  <v-btn
                    color="blue"
                    text="Edit Release info"
                    prepend-icon="mdi-pencil"
                    :disabled="!isOTAContentAdmin"
                    @click="editRollout(item)"
                  />

                  <v-btn
                    v-if="item.filters.includes('label:criteria:ota-content-private-users')"
                    color="blue"
                    text="Private group members"
                    prepend-icon="mdi-account-group"
                    :disabled="!isOTAContentAdmin"
                    @click="editMembersDialog = true"
                  />

                  <v-btn
                    v-if="item.state === 'active'"
                    color="red"
                    text="Disable rollout"
                    prepend-icon="mdi-block-helper"
                    :disabled="!isOTAContentAdmin"
                    @click="disableRollout(item)"
                  />

                  <v-btn
                    v-else
                    color="green"
                    text="Enable rollout"
                    prepend-icon="mdi-check-bold"
                    :disabled="!isOTAContentAdmin"
                    @click="enableRollout(item)"
                  />

                  <template v-if="!!item.flavor">
                    <v-btn
                      v-if="item.filters.includes('label:criteria:ota-content-private-users')"
                      color="purple"
                      text="Release to experimental"
                      prepend-icon="mdi-flask-outline"
                      :disabled="!isOTAContentAdmin || releasing || actions.length > 0 || isOldVersion(item)"
                      @click="elevateRolloutToExperimental(item)"
                    />

                    <template
                      v-if="
                        item.flavor === 'experimental' &&
                        !item.filters.includes('label:criteria:ota-content-private-users')
                      "
                    >
                      <v-btn
                        v-if="
                          releasing ||
                          actions.length > 0 ||
                          !metadata[item.value.version] ||
                          !metadata[item.value.version].releasedAt
                        "
                        color="green"
                        text="Release to production"
                        prepend-icon="mdi-rocket-launch-outline"
                        :disabled="!isOTAContentAdmin || releasing || actions.length > 0 || isOldVersion(item)"
                        @click="elevateRolloutToProduction(item)"
                      />

                      <v-btn
                        v-else
                        color="green"
                        target="_blank"
                        text="Confirm in GitHub"
                        rel="noopener noreferrer"
                        prepend-icon="mdi-check-circle-outline"
                        href="https://github.com/jouzen/insight-engine/pulls"
                        :disabled="!isOTAContentAdmin || actions.length > 0 || isOldVersion(item)"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>

  <v-dialog v-if="!!releaseInfo" width="600" :model-value="true">
    <v-card>
      <v-card-title>Edit release info</v-card-title>

      <v-card-text>
        <v-text-field v-model="releaseInfo.link" :label="'Release info page for version ' + releaseInfo.version" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" @click="releaseInfo = null" />
        <v-btn text="Save" color="primary" @click="saveReleaseInfo()" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="!!editMembersDialog" width="600" :model-value="true">
    <v-card>
      <v-card-title>Edit private group members</v-card-title>

      <v-card-text>
        <v-text-field
          v-model.trim="userText"
          v-lowercase
          single-line
          prepend-icon="mdi-account"
          label="Email or UUID to add"
          :loading="loading || waiting"
          :hint="
            userUid.includes('@')
              ? 'Press enter to check the email'
              : !!userText && validateUserUid(userText)
                ? 'Press enter to add the uuid'
                : ''
          "
          :rules="[
            (text: string) =>
              text && !text.includes('@')
                ? validateUserUid(text)
                : !text || loading || userUid
                  ? true
                  : 'No account found with given email',
          ]"
          @update:model-value="userUid = userText"
          @keyup.enter="addAccountToLabel()"
        />

        <v-data-table
          hide-default-footer
          :items="labelUsers"
          :headers="labelHeaders"
          :items-per-page="1000"
          :no-data-text="userUid ? 'No users matching uuid' : 'No users saved for the group'"
        >
          <template #item.createdAt="{ item }">
            {{ item.createdAt ? $dayjs(item.createdAt).format('DD MMM YYYY') : 'Just now' }}
          </template>

          <template #item.delete="{ item }">
            <v-btn icon="mdi-close" @click="removeUserFromLabel(item)" />
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" @click="editMembersDialog = false" />
        <v-btn text="Save" color="primary" @click="saveLabelUsers()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { validate, version } from 'uuid'

  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { serverTimestamp } from '@firebase/firestore'

  import { compare } from 'compare-versions'

  import { labelHeaders, otaHeaders } from '#views/rollouts/constants'

  import { AppStore, LabelsStore, ProjectsStore, RolloutsStore } from '#stores'

  @Component({})
  class ContentRollouts extends Vue {
    public userUid = ''
    public userText = ''

    public expanded: any[] = []

    public releaseInfo: any = null

    public editMembersDialog = false

    public readonly otaHeaders = otaHeaders
    public readonly labelHeaders = labelHeaders

    public readonly env = import.meta.env.VITE_APP_ENV

    private readonly appStore = new AppStore()
    private readonly labelsStore = new LabelsStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly rolloutsStore = new RolloutsStore()

    private interval: number | undefined = undefined

    public get loading() {
      return this.rolloutsStore.loading
    }

    public get waiting() {
      return this.labelsStore.waiting
    }

    public get actions() {
      return this.rolloutsStore.actions
    }

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects
    }

    public get metadata() {
      return this.rolloutsStore.metadata
    }

    public get rollouts() {
      return this.rolloutsStore.rollouts
    }

    public get releasing() {
      return this.rolloutsStore.releasing
    }

    public get labelUsers() {
      return this.labelsStore.users.filter((u: any) => u.userUid.includes(this.userUid))
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin
    }

    @Watch('releasing', { immediate: true })
    protected onReleasingChanged() {
      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = undefined
      }

      if (this.isOTAContentAdmin) {
        this.rolloutsStore.checkGitHubStatuses()

        this.interval = window.setInterval(this.updateRollouts, (this.releasing ? 1 : 5) * 60 * 1000)
      }
    }

    public mounted() {
      this.rolloutsStore.listOtaRollouts('insight_content')

      this.labelsStore.loadLabelUsers('stage', 'criteria:ota-content-private-users')
    }

    public beforeUnmount() {
      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = undefined
      }
    }

    public updateRollouts() {
      this.rolloutsStore.listOtaRollouts('insight_content')

      if (this.isOTAContentAdmin) {
        this.rolloutsStore.checkGitHubStatuses()
      }

      if (this.releasing && !this.actions.length) {
        this.rolloutsStore.releasing = false
      }
    }

    public saveLabelUsers() {
      this.labelsStore.saveLabelUsers('stage', 'criteria:ota-content-private-users')

      this.editMembersDialog = false
    }

    public saveReleaseInfo() {
      this.rolloutsStore.saveRolloutInfo(this.releaseInfo)

      this.releaseInfo = null
    }

    public editRollout(rollout: any) {
      this.releaseInfo = { ...this.metadata[rollout.value.version], version: rollout.value.version }
    }

    public enableRollout(rollout: any) {
      this.rolloutsStore.enableOtaRollout('insight_content', rollout.slug)
    }

    public disableRollout(rollout: any) {
      this.rolloutsStore.disableOtaRollout('insight_content', rollout.slug)
    }

    public isOldVersion(rollout: any) {
      return !!this.rollouts
        .filter((r) => r !== rollout)
        .find(
          (r: any) =>
            (compare(r.value.version, rollout.value.version, '>=') && !!rollout.flavor) ||
            (compare(r.value.version, rollout.value.version, '>') && !r.flavor && !rollout.flavor),
        )
    }

    public validateUserUid(uuid: string) {
      return (validate(uuid) && version(uuid) === 4) || 'Not in email or uuid format'
    }

    public rolloutStatusColor(rollout: any) {
      return rollout.filters.includes('label:criteria:ota-content-private-users')
        ? 'pink'
        : !rollout.flavor || rollout.flavor === 'release'
          ? 'blue'
          : rollout.flavor === 'debug'
            ? 'green'
            : 'purple'
    }

    public rolloutProjectName(rollout: any) {
      const rollouts = this.metadata[rollout.value.version]?.rollouts || {}

      const project = this.projects.find((p) => p.id === rollouts[rollout.slug]?.project)

      return 'Updated content from ' + (project?.name || rollouts[rollout.slug]?.project || 'unknown source')
    }

    public async addAccountToLabel() {
      if (this.userUid.includes('@')) {
        const uuids = await this.labelsStore.convertEmailsToUids('stage', [this.userText])

        this.userUid = uuids[0] || this.userText
      } else if (this.validateUserUid(this.userUid) && !this.labelUsers.find((u: any) => u.userUid === this.userUid)) {
        this.labelsStore.users.unshift({ userUid: this.userUid })

        this.userText = ''
        this.userUid = ''

        this.$forceUpdate()
      }
    }

    public removeUserFromLabel(user: any) {
      this.labelsStore.users.splice(this.labelsStore.users.indexOf(user), 1)
    }

    public elevateRolloutToProduction(rollout: any, _flavors?: string[]) {
      this.$confirm('Elevate this rollout to production?', `${rollout.value.version}`).then(async (confirmed) => {
        this.rolloutsStore.releasing = true

        if (confirmed && this.env === 'release') {
          await this.rolloutsStore.productionOtaRelease({
            slug: rollout.value.slug,
            version: rollout.value.version,
          })

          await this.rolloutsStore.saveRolloutInfo({
            version: rollout.value.version,
            releasedAt: serverTimestamp(),
          })
        }
      })
    }

    public elevateRolloutToExperimental(rollout: any, _flavors?: string[]) {
      this.$confirm('Elevate this rollout to experimental?', `${rollout.value.version}`).then(async (confirmed) => {
        this.rolloutsStore.releasing = true

        if (confirmed && this.env === 'release') {
          const rollouts = this.metadata[rollout.value.version]?.rollouts || {}

          const project = this.projects.find((p) => p.id === rollouts[rollout.slug]?.project)

          await this.rolloutsStore.experimentalOtaRollout({
            project: project.id,
            slug: rollout.value.slug,
            version: rollout.value.version,
          })
        }
      })
    }
  }

  export default toNative(ContentRollouts)
</script>
