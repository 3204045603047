// sort-imports-ignore

declare let prettier: any
declare let prettierPlugins: any

// Returns the components from a logical statement.
function splitStatement(statement: string) {
  return statement
    .split(/&{2}|\|{2}/)
    .map((c) => c.trim())
    .filter((c) => c.length > 0)
    .map((c) => {
      // Balance parantheses.
      const openCount = (c.match(/\(/g) ?? []).length
      const closedCount = (c.match(/\)/g) ?? []).length
      const delta = openCount - closedCount
      if (delta < 0) {
        return c.slice(0, delta)
      }
      return c.slice(delta, c.length)
    })
}

function checkOperators(statement: string) {
  if (/[^&]&[^&]/.exec(statement)) {
    throw String("Statement contains a binary operator '&'.\nDid you mean to use logical and '&&' instead?")
  }
  if (/[^|]\|[^|]/.exec(statement)) {
    throw String("Statement contains a binary operator '|'.\nDid you mean to use logical or '||' instead?")
  }
  if (/[^!<>=]=[^=]/.exec(statement)) {
    throw String("Statement contains a an assignment operator '='.\nDid you mean to use compare '===' instead?")
  }
  if (/[^!=]={2}[^=]/.exec(statement)) {
    throw String(
      "Statement contains an unsafe comparison operator '=='.\nDid you mean to use safe compare '===' instead?",
    )
  }
  if (/!=[^=]/.exec(statement)) {
    throw String(
      "Statement contains an unsafe comparison operator '!='.\nDid you mean to use safe compare '!==' instead?",
    )
  }
}

function trimSpaces(str: string) {
  // Trim spaces around math operators +, * and -. And also space after comma.
  // so that eg. Math.abs(day(0).sleep.sleep_midpoint_offset_minutes - day(0).sleep.bedtime_midpoint_offset_minutes) won't fail
  return str
    .replace(/\s-\s/gi, '-')
    .replace(/,\s/gi, ',')
    .replace(/\s\+\s/gi, '+')
    .replace(/\s\*\s/gi, '*')
}

function parseWrappers(s: string) {
  let parsed: any

  // Check if string is wrapped in !() and remove !()
  if (s.startsWith('!(') && s.endsWith(')')) {
    s = s.replace(/^!\(/, '').replace(/\)$/, '')
  } // Check if string is wrapped only in () and remove ()
  else if (s.startsWith('(') && s.endsWith(')')) {
    s = s.replace(/^\(/, '').replace(/\)$/, '')
  }

  // Check if string is wrapped in e.g. format_integer() and remove wrapper
  if (/^[a-zA-Z_-]+?\((.*)\)/.test(s) && s.endsWith(')') && !s.startsWith('day') && !s.startsWith('week')) {
    parsed = /^[^.]+?\((.*)\)/.exec(s)
    // Check if there are two or multiple strings wrapped in e.g. format_integer and continue without removing
    if (parsed && parsed.length >= 2 && /&&/.test(parsed[1]) && /[a-zA-Z_-]+?\((.*)/.test(parsed[1])) {
      parsed[1] = null
    }
  }
  // Check if wrapper has another wrapper inside
  if (parsed != null) {
    s = parseWrappers(`(${parsed[1]})`)
  }

  return s
}

export async function simplifyStatement(statement: string) {
  try {
    return {
      success: true,
      value: statement
        ? (
            await prettier.format(statement.trim(), {
              semi: false,
              parser: 'babel',
              printWidth: 10000,
              singleQuote: true,
              plugins: prettierPlugins,
            })
          )
            .replace(/^;/, '')
            .replace(/\n/gi, '')
            .replace(/\s+/gi, ' ')
            .replace(/\s\./gi, '.')
        : '',
    }
  } catch (e) {
    return {
      success: false,
      value: e,
    }
  }
}

// Evaluates statement with eval and returns result or returns statement.
export function evaluateStatement(statement: string, type: string, prefix: string = '') {
  try {
    checkOperators(statement)

    let result: any

    // Evaluate each component to catch errors that could be hidden by short-circuit evaluation.
    splitStatement(parseWrappers(`${statement}`)).forEach((s) => {
      result = eval(prefix + s)

      let str = s
      s = parseWrappers(s)

      trimSpaces(s)
        .split(/\s/)
        .forEach((s) => {
          if (/\./gi.test(s) && !Number(s) && result !== undefined) {
            str = s
            result = eval(prefix + str)
          }
        })

      if (result === undefined) {
        throw String(`${str} is not defined`)
      }
    })

    result = eval(prefix + statement)

    if (typeof result === type) {
      return { success: true, message: `${result}` }
    } else {
      return {
        success: false,
        message: `Statement evaluated to ${typeof result}. Expected ${type}.`,
      }
    }
  } catch (err: any) {
    return { success: false, message: err.toString() }
  }
}
