<template>
  <div class="d-flex flex-grow-1 flex-shrink-1 align-center justify-center">
    <template v-if="!mediaUrl">
      <div style="font-size: 40px; text-align: center; opacity: 0.3; padding: 150px 25px; min-width: 100%">
        {{ loading ? 'Loading...' : 'No file selected' }}
      </div>
    </template>
    <template v-else>
      <div>
        <audio v-if="mediaType.startsWith('audio')" ref="audioPlayerRef" controls>
          <source :src="mediaUrl" />
        </audio>

        <div v-else-if="mediaType.startsWith('video')" style="width: 300px">
          <video ref="videoPlayerRef" controls style="width: 100%">
            <source :src="mediaUrl" />
          </video>
        </div>

        <div v-else-if="mediaType.startsWith('image')" style="min-width: 350px; max-width: 350px; position: relative">
          <v-img :src="mediaUrl" contain style="max-height: 350px" />

          <div
            v-if="thumbUrl"
            style="
              position: absolute;
              bottom: 12px;
              right: 12px;
              border: 2px solid rgba(100, 100, 100, 0.9);
              border-radius: 12px;
              min-width: 80px;
              min-height: 80px;
              background-color: white;
              z-index: 1;
              overflow: hidden;
            "
          >
            <v-img :src="thumbUrl" contain style="max-height: 80px" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  @Component({})
  class MediaFilePreview extends Vue {
    @Prop() public loading!: boolean

    @Prop() public mediaUrl!: string
    @Prop() public thumbUrl!: string
    @Prop() public mediaType!: string

    @Ref() private readonly audioPlayerRef: HTMLAudioElement | null = null
    @Ref() private readonly videoPlayerRef: HTMLVideoElement | null = null

    @Watch('mediaUrl')
    protected onMediaUrlChanged(val: any) {
      if (val) {
        this.resetPreview()
      }
    }

    @Watch('mediaType')
    protected onMediaFileChanged(val: any) {
      if (val) {
        this.resetPreview()
      }
    }

    @Debounce(100)
    private resetPreview() {
      if (this.mediaUrl) {
        if (this.mediaType.startsWith('audio')) {
          this.audioPlayerRef?.load()
        } else if (this.mediaType.startsWith('video')) {
          this.videoPlayerRef?.load()
        }
      }
    }
  }

  export default toNative(MediaFilePreview)
</script>
