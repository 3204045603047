export const otaHeaders: any = [
  /* {
    key: 'icon',
    width: 20,
    sortable: false,
  }, */
  {
    key: 'data-table-expand',
  },
  {
    key: 'value.version',
    title: 'Version',
    sortable: false,
    width: 90,
  },
  {
    key: 'release',
    title: 'Release',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'creator',
    title: 'Creator',
    sortable: false,
  },
  {
    key: 'age',
    title: 'Age',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Status',
    align: 'end',
    cellClass: 'text-no-wrap',
    sortable: false,
    width: 120,
  },
]

export const labelHeaders: any = [
  {
    key: 'userUid',
    title: 'UID',
    sortable: false,
    cellClass: 'caption text-no-wrap px-3',
  },
  {
    key: 'createdAt',
    title: 'Added',
    align: 'end',
    sortable: false,
    cellClass: 'caption text-right text-no-wrap px-3',
  },
  {
    key: 'delete',
    sortable: false,
    align: 'end',
    width: 20,
  },
]

export const changesHeaders: any = [
  {
    key: 'data-table-expand',
  },
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'state',
    title: 'State',
  },
  {
    key: 'action',
    title: 'Action',
  },
  {
    key: 'changes',
    title: 'Changes',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const scheduleHeaders: any = [
  {
    key: 'apply_at',
    width: '50%',
    title: 'Rollout step activation',
  },
  {
    key: 'percentile',
    title: 'Percentile',
  },
]

export const packageTypes: any = [
  {
    icon: 'mdi-text-box-outline',
    title: 'App content',
    value: 'content',
  },
  {
    icon: 'mdi-cog-outline',
    title: 'Assa config',
    value: 'config',
  },
]

export const appPlatforms: any = {
  android: 'Android',
  ios: 'iOS',
}

export const ignoreReleaseKeys = ['author', 'project', 'projects', 'createdAt', 'updatedAt', 'publishedAt']
