<template>
  <v-select
    v-if="templateNamedCriterias.length > 0"
    class="mb-n4"
    label="Main segment"
    multiple
    readonly
    menu-icon="mdi-filter-outline"
    :items="validNamedCriterias"
    :model-value="templateNamedCriterias"
    @update:model-value="updateNamedCriterias($event.concat(additionalNamedCriterias))"
  >
    <template #selection="{ item, index }">
      {{ item.title.toUpperCase() }}

      <v-chip
        v-if="additionalNamedCriterias.length > 0 || index < templateNamedCriterias.length - 1"
        size="small"
        class="caption mt-n1 ml-2"
      >
        AND
      </v-chip>
    </template>
  </v-select>

  <v-autocomplete
    class="mb-n4"
    multiple
    small-chips
    persistent-placeholder
    no-data-text="No existing segments"
    :items="validNamedCriterias"
    :model-value="additionalNamedCriterias"
    :label="templateNamedCriterias.length > 0 ? 'Restrictions' : 'Target segments'"
    :placeholder="
      templateNamedCriterias.length > 0 ? 'No segment restrictions' : 'Need to select at least one segment!'
    "
    @update:model-value="updateNamedCriterias(templateNamedCriterias.concat($event))"
  >
    <template #prepend-inner>
      <v-tooltip
        location="top start"
        :text="useInvertedTargeting ? 'Switch to normal targeting' : 'Switch to inversed targeting'"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="ml-n2"
            icon="mdi-swap-horizontal"
            :disabled="!additionalNamedCriterias.length"
            @mousedown.stop
            @click.stop="toggleNamedCriteriasInverted()"
          />
        </template>
      </v-tooltip>
    </template>

    <template #selection="{ item, index }">
      <div class="d-flex flex-no-wrap" style="word-break: break-word">
        <span class="pt-1" style="font-size: 14px">
          <span v-if="useInvertedTargeting" class="text-orange">NOT</span>

          {{ item.title.toUpperCase() }}
        </span>

        <v-chip
          v-if="index !== additionalNamedCriterias.length - 1"
          size="small"
          class="flex-shrink-0 caption text-blue ml-2"
          @mousedown.stop
          @click.stop="toggleNamedCriteriasOperator()"
        >
          {{ namedCriteriasOperator(override).toUpperCase() }}
        </v-chip>
      </div>
    </template>
  </v-autocomplete>

  <div v-if="currentCustomCriterias.length > 0" class="my-8" style="position: relative; text-align: center">
    <v-divider v-if="currentCustomCriterias.length > 1" style="border-color: black" />

    <span
      class="px-4"
      style="
        position: absolute;
        font-weight: bold;
        transform: translateX(-50%);
        color: rgb(var(--v-theme-on-surface));
        background: rgb(var(--v-theme-surface));
      "
      :style="{ top: currentCustomCriterias.length > 1 ? '-14px' : '-28px' }"
    >
      <v-chip small>{{ currentCustomCriterias.length > 1 ? 'AND ONE OF' : 'AND' }}</v-chip>
    </span>
  </div>

  <CriteriaPreds
    action="Add custom criteria"
    :limited="unauthenticated"
    :expressions="currentCustomCriterias"
    @change="updateCustomCriterias($event)"
    @validate-expressions="emitValidate()"
  />

  <v-alert
    v-if="hasTwoOrMoreUIDPredicates"
    text="Multiple User UID's are preferrably handeled with segments, as they can be managed independently"
    class="mt-2 mb-6"
    color="warning"
  />
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Expression } from '@jouzen/feature-mgmt-api/criteria'
  import { Context } from '@jouzen/feature-mgmt-api/metadata'

  import { templateCriterias } from '#views/features/constants'

  import {
    createNamedCriteria,
    forEachNamedCriteria,
    namedCriteriasInverted,
    namedCriteriasOperator,
  } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { SegmentsStore } from '#stores'

  import { Override, Reference } from '#types'

  @Component({})
  class RolloutTarget extends Vue {
    @Prop() public active!: number

    @Prop() public override!: Override

    @Prop() public references!: Reference[]

    @Prop() public unauthenticated!: boolean

    public useInvertedTargeting = false

    public readonly namedCriteriasOperator = namedCriteriasOperator

    protected readonly segmentsStore = new SegmentsStore()

    public get existingCriterias() {
      return this.segmentsStore.criterias
    }

    public get validNamedCriterias() {
      const commonSegments = this.existingCriterias
        .filter(
          (c) =>
            getProjectKey(c) === 'common' &&
            c.expression?.oneOf?.$case !== 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)) &&
            !templateCriterias[this.override.metadata!.informative!.labels.template]?.includes(c.metadata?.name),
        )
        .map((c) => ({
          title: c.metadata?.name?.toUpperCase(),
          value: c.metadata?.name,
        }))

      const userGroupLabelSegments = this.existingCriterias
        .filter(
          (c) =>
            !commonSegments.find((s) => s.value === c.metadata?.name) &&
            c.expression?.oneOf?.$case === 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)),
        )
        .sort((a, b) => a.metadata!.name!.localeCompare(b.metadata!.name))
        .map((c) => ({
          title: c.metadata?.name.toUpperCase(),
          value: c.metadata?.name,
        }))

      const customPredicatesSegments = this.existingCriterias
        .filter(
          (c) =>
            !commonSegments.find((s) => s.value === c.metadata?.name) &&
            c.expression?.oneOf?.$case !== 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)),
        )
        .sort((a, b) => a.metadata!.name!.localeCompare(b.metadata!.name))
        .map((c) => ({
          title: c.metadata?.name.toUpperCase(),
          value: c.metadata?.name,
        }))

      return ([] as any[]).concat(
        {
          value: 'common-target-segments',
          title: 'Common target segments',
          props: { disabled: true, class: 'label-only' },
        },
        commonSegments,
        {
          value: 'user-group-label-segments',
          title: 'User group label segments',
          props: { disabled: true, class: 'label-only' },
        },
        userGroupLabelSegments,
        {
          value: 'custom-predicates-segments',
          title: 'Custom predicates segments',
          props: { disabled: true, class: 'label-only' },
        },
        customPredicatesSegments,
      )
    }

    public get currentNamedCriterias() {
      const criterias: string[] = []

      forEachNamedCriteria(this.override, (criteria) => criterias.push(criteria))

      return criterias
    }

    public get currentCustomCriterias() {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      return expression?.oneOf?.$case === 'or' ? expression?.oneOf.or.expressions : []
    }

    public get templateNamedCriterias() {
      return this.currentNamedCriterias.filter((c) =>
        templateCriterias[this.override.metadata!.informative!.labels.template]?.includes(c),
      )
    }

    public get additionalNamedCriterias() {
      return this.currentNamedCriterias.filter(
        (c) => !templateCriterias[this.override.metadata!.informative!.labels.template]?.includes(c),
      )
    }

    public get hasTwoOrMoreUIDPredicates() {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      const expressions = (expression?.oneOf?.$case === 'or' && expression?.oneOf.or.expressions) || []

      const predicates = expressions.flatMap((e) => (e.oneOf as any)[e.oneOf!.$case].expressions)

      return (
        predicates.filter((e) => {
          return (
            e.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'userUid' ||
            e.oneOf?.not?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'userUid'
          )
        }).length >= 2
      )
    }

    @Watch('override', { immediate: true })
    protected overrideChanged() {
      this.useInvertedTargeting = namedCriteriasInverted(this.override)
    }

    @Emit('validate')
    public emitValidate() {
      return !!this.currentNamedCriterias.length
    }

    public updateNamedCriterias(value: string[], operator?: string) {
      operator = operator || namedCriteriasOperator(this.override)

      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 0 : 1

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      if (expression) {
        const templateExpressions: any[] = []
        const additionalExpressions: any[] = []

        value.forEach((criteria) => {
          const e =
            this.useInvertedTargeting &&
            !templateCriterias[this.override.metadata!.informative!.labels.template]?.includes(criteria)
              ? ({
                  oneOf: {
                    $case: 'not',
                    not: {
                      expression: createNamedCriteria(
                        criteria,
                        this.existingCriterias.find((c) => c.metadata?.name === criteria)?.metadata?.project,
                      ),
                    },
                  },
                } as Expression)
              : createNamedCriteria(
                  criteria,
                  this.existingCriterias.find((c) => c.metadata?.name === criteria)?.metadata?.project,
                )

          if (templateCriterias[this.override.metadata!.informative!.labels.template]?.includes(criteria)) {
            templateExpressions.push(e)
          } else {
            additionalExpressions.push(e)
          }
        })

        expression.oneOf = {
          $case: 'and',
          and: {
            expressions: [
              ...templateExpressions,
              {
                oneOf: {
                  $case: operator,
                  [operator]: {
                    expressions: additionalExpressions,
                  },
                } as any,
              },
            ],
          },
        }
      }

      this.emitValidate()
    }

    public updateCustomCriterias(expressions: Expression[]) {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      if (
        !expression &&
        this.override.criteria?.oneOf?.$case === 'and' &&
        this.override.criteria?.oneOf?.and.expressions
      ) {
        this.override.criteria.oneOf.and.expressions.push({
          oneOf: {
            $case: 'or',
            or: {
              expressions: expressions,
            },
          },
        })
      } else if (
        expression &&
        expression.oneOf?.$case === 'or' &&
        !expression.oneOf.or.expressions.length &&
        this.override.criteria?.oneOf?.$case === 'and' &&
        this.override.criteria?.oneOf?.and.expressions
      ) {
        this.override.criteria?.oneOf?.and.expressions.splice(index, 1)
      }

      this.emitValidate()
    }

    public toggleNamedCriteriasInverted() {
      this.useInvertedTargeting = !this.useInvertedTargeting

      this.updateNamedCriterias(this.currentNamedCriterias)
    }

    public toggleNamedCriteriasOperator() {
      const current = namedCriteriasOperator(this.override)

      this.updateNamedCriterias(this.currentNamedCriterias, current === 'and' ? 'or' : 'and')
    }
  }

  export default toNative(RolloutTarget)
</script>

<style lang="scss">
  .v-menu {
    .v-list {
      .label-only {
        .v-checkbox-btn {
          display: none !important;
        }
      }
    }
  }
</style>
