<template>
  <v-app-bar>
    <v-app-bar-title>
      <v-menu offset="18">
        <template #activator="{ props }">
          <span v-bind="props" style="cursor: pointer">
            OTA Rollouts / {{ packageTypes.find((t: any) => t.value === packageType)?.title }} ({{
              otaRollouts.length
            }})
          </span>

          <v-icon>mdi-triangle-small-down</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="type in packageTypes"
            :key="type.value"
            :prepend-icon="type.icon"
            :title="type.title + ' rollouts'"
            :active="type.value === packageType"
            :style="type.value === packageType && 'pointer-events: none'"
            @click="$router.push('/rollouts/' + type.value).catch(() => {})"
          />
        </v-list>
      </v-menu>
    </v-app-bar-title>

    <v-spacer />

    <v-tooltip v-if="packageType === 'content' && isOTAContentAdmin" text="Re-trigger GitHub action">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-restart-alert"
          :disabled="isReleasing || !project || !isOTAContentAdmin"
          @click="reTriggerGithub()"
        />
      </template>
    </v-tooltip>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">OTA rollouts allow safe release of new contents and configs</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isOTAContentAdmin">
            Your content admin rights allow you to create new rollouts and elevate existing rollouts
          </template>
          <template v-else>You can only view ota rollouts, apply for content admin rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          v-if="packageType === 'content'"
          color="primary"
          text="Create new release"
          :disabled="
            !project || (project && !project?.sections?.content && !project?.sections?.insights) || !isOTAContentAdmin
          "
          @click="release = 'confirm'"
        />
        <v-btn
          v-else
          color="primary"
          text="Create new rollout"
          :disabled="
            !project || (project && !project?.sections?.content && !project?.sections?.insights) || !isOTAContentAdmin
          "
          @click="release = 'confirm'"
        />
      </v-col>
    </v-row>

    <v-alert v-if="project?.state === 'Blocked'" type="warning" class="my-8">
      <v-row align="center">
        <v-col class="grow">
          <p>{{ `Project status: ${project.state} - ${project.status}` }}</p>
        </v-col>
      </v-row>
    </v-alert>

    <v-row v-if="project && !project?.sections?.content && !project?.sections?.insights">
      <v-col class="d-flex justify-center" cols="12">
        <v-divider />

        <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
          Selected project does not use OTA rollouts, you can select another project from the top bar or unselect the
          current project to see all the existing OTA rollouts.
        </v-alert>
      </v-col>
    </v-row>

    <template v-else>
      <ContentRollouts v-if="packageType === 'content'" />

      <ConfigRollouts v-else-if="packageType === 'config'" />
    </template>
  </v-container>

  <ChangesDialog v-if="release === 'confirm'" @cancel="release = ''" @confirm="releaseChanges($event)" />

  <ReleaseDialog
    v-if="release === 'creating'"
    :release-content="changes"
    @cancel="release = ''"
    @released="monitorRelease()"
  />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { packageTypes } from '#views/rollouts/constants'

  import { AppStore, ProjectsStore, RolloutsStore } from '#stores'

  @Component({})
  class RolloutsView extends Vue {
    @Prop() public packageType!: string

    public release = ''

    public changes = {}

    public readonly packageTypes = packageTypes

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly rolloutsStore = new RolloutsStore()

    public get actions() {
      return this.rolloutsStore.actions
    }

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects
    }

    public get isReleasing() {
      return this.rolloutsStore.releasing
    }

    public get otaRollouts() {
      return this.rolloutsStore.rollouts
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin
    }

    public monitorRelease() {
      this.release = ''

      this.rolloutsStore.releasing = true
    }

    public releaseChanges(changes: any) {
      this.changes = changes
      this.release = 'creating'
    }

    public async reTriggerGithub() {
      const actions = await this.rolloutsStore.checkGitHubStatuses()

      if (!actions?.length) {
        this.rolloutsStore.releasing = true

        await this.rolloutsStore.experimentalOtaRollout({
          project: this.project?.id,
        })
      }
    }
  }

  export default toNative(RolloutsView)
</script>
