<template>
  <v-navigation-drawer
    v-model="isOpen"
    width="450"
    location="end"
    elevation="0"
    disable-route-watcher
    disable-resize-watcher
    :temporary="$vuetify.display.mdAndDown"
    @update:model-value="!$event ? close() : null"
  >
    <v-card class="d-flex flex-column fill-height">
      <v-card-title class="d-flex flex-row shrink">
        <span>{{ !original?.metadata?.changeRecord?.createdAt ? 'Create' : 'Edit' }} segment</span>

        <v-spacer />

        <v-btn icon="mdi-close" color="primary" class="mt-n2 mr-n4" @click="close()" />
      </v-card-title>

      <v-card-text id="segment-panel" class="fill-height" style="overflow: auto">
        <div id="segment-panel-top" />

        <CriteriaEditor
          v-if="edited"
          :edited="edited"
          :original="original"
          :features="features"
          :criterias="criterias"
          :overrides="overrides"
          @validate="validateCriteria($event)"
        />

        <div id="segment-panel-bottom" />
      </v-card-text>

      <div id="segment-panel-bottom" />

      <v-card-actions class="d-flex flex-row flex-shrink-1 align-center justify-center">
        <v-btn
          class="my-4"
          color="primary"
          :text="!original?.metadata?.changeRecord?.createdAt ? 'Create' : 'Update'"
          :disabled="!isValid || isLoading || !isAppFeatureAdmin"
          @click="updateCriteria()"
        />
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { cloneDeep, isEqual } from 'lodash-es'

  import { useGoTo } from 'vuetify'

  import { Component, Emit, Prop, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Project, project_StaticIdFromJSON } from '@jouzen/feature-mgmt-api/metadata'

  import { getProjectName } from '#views/projects/utilities'

  import { AppStore, ProjectsStore, SegmentsStore } from '#stores'

  import { Criteria, CriteriaOverrides, Feature } from '#types'

  @Component({})
  class SegmentPanel extends Vue {
    @Prop() public criteria!: Criteria

    @Prop() public features!: Feature[]
    @Prop() public criterias!: Criteria[]

    @Prop() public overrides!: CriteriaOverrides

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public isOpen = false
    public isValid = false

    public edited: Criteria | null = null
    public original: Criteria | null = null

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly segmentsStore = new SegmentsStore()

    public get isLoading() {
      return this.segmentsStore.loading
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingProjects() {
      return this.projectsStore.projects
    }

    public get isAppFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin
    }

    @Emit('open')
    public open() {
      this.initPanel()

      this.isOpen = true

      this.appStore.navDrawer = 'segment'

      return
    }

    @Emit('close')
    public close() {
      this.appStore.closeNavDrawer('segment')

      this.isOpen = false

      return
    }

    @Emit('change')
    public emitChange() {
      return this.edited
    }

    @Watch('appStore.navDrawer', { immediate: true })
    protected navDrawerChanged() {
      if (this.appStore.navDrawer === 'segment') {
        this.open()
      } else {
        this.close()
      }
    }

    public initPanel() {
      this.original = this.criteria

      this.edited = cloneDeep(this.criteria)

      console.log('Editing segment', this.criteria)

      if (!this.original.metadata?.changeRecord?.createdAt) {
        if (!this.edited?.metadata?.informative?.labels?.contact) {
          this.edited.metadata!.informative!.labels.contact = this.appStore.email || ''
        }

        if (this.activeProject) {
          const projectId = this.existingProjects.find((p) => p.id === this.activeProject.id).key || 'testing_waltari'

          const staticProject = project_StaticIdFromJSON(projectId.toUpperCase()) || -1

          this.edited.metadata!.project =
            staticProject === -1
              ? ({ oneOf: { $case: 'external', external: projectId } } as Project)
              : ({ oneOf: { $case: 'apiStatic', apiStatic: staticProject } } as Project)

          if (!this.edited?.metadata?.informative?.labels?.project) {
            this.edited.metadata!.informative!.labels.project = this.activeProject.id
          }
        }
      }

      this.goTo('#segment-panel-top', { container: '#segment-panel' })

      this.isOpen = true

      this.emitChange()
    }

    public async updateCriteria() {
      if (!this.edited?.metadata?.changeRecord?.createdAt) {
        this.$confirm(
          'Is the name and project correct?',
          'These can not be changed later so please make sure everything is correct!' +
            '<div>' +
            `<div class="mt-4 overline">Name</div>${this.edited?.metadata?.name}` +
            `<div class="mt-4 overline">Project</div>${getProjectName(this.edited!)}` +
            '</div>',
        ).then(async (confirmed) => {
          if (confirmed) {
            await this.segmentsStore.updateCriteria(this.edited)

            this.$router.push(`/segments/${this.edited?.metadata?.name}`)

            this.close()
          }
        })
      } else {
        await this.segmentsStore.updateCriteria(this.edited)

        this.close()
      }
    }

    public validateCriteria(criteria: Criteria) {
      if (criteria && !isEqual(this.edited, this.original)) {
        let isValid = true

        const checkEmptyValues = function (_key: string, value: any) {
          if (typeof value == 'string') {
            isValid = value ? isValid : false
          } else if (value && typeof value == 'object') {
            Object.keys(value).forEach((key) => {
              if (!['labels', 'owners', 'displayName'].includes(key)) {
                checkEmptyValues(key, value[key])
              }
            })
          }
        }

        Object.keys(criteria).forEach((key) => {
          const value: any = criteria[key as keyof typeof criteria]

          if (['metadata', 'parameters', 'expression'].includes(key)) {
            checkEmptyValues(key, value)
          }
        })

        this.isValid = isValid
      } else {
        this.isValid = false
      }
    }
  }

  export default toNative(SegmentPanel)
</script>

<style lang="scss" scoped>
  .v-custom-label label {
    font-size: 13px !important;
  }

  :deep(.parameters),
  :deep(.predicates) {
    .v-select,
    .v-text-field {
      input,
      label,
      .v-select__selection-text {
        font-size: 14px !important;
      }
    }
  }

  :deep(.predicate-operator) {
    position: absolute;
    left: -4px;
    bottom: 12px;
    font-size: 11px;
  }
</style>
