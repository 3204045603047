<template>
  <v-timeline-item :dot-color="dotColor">
    <h2>{{ releaseTierMeaning[releaseTier] }}</h2>

    <p class="text-subtitle-2 text-grey-darken-1 font-weight-regular">
      <template v-if="releaseTier === ReleaseTiers.PRIORITY_GROUP_TESTING">
        Selected group of Ouranians.
        <span v-if="selectedBuild.platform === 'ios'">(Priority group)</span>
        <span v-if="selectedBuild.platform === 'android'">({{ AndroidReleaseTracks.PRIORITY_GROUP }} track)</span>
      </template>

      <template v-if="releaseTier === ReleaseTiers.INTERNAL_TESTING">
        All Ouranian testers.
        <span v-if="selectedBuild.platform === 'ios'">(Tier 1)</span>
        <span v-if="selectedBuild.platform === 'android'">({{ AndroidReleaseTracks.TIER_1 }} track)</span>
      </template>

      <template
        v-if="[ReleaseTiers.SELECTED_EXTERNAL_TESTING, ReleaseTiers.FULL_EXTERNAL_TESTING].includes(releaseTier)"
      >
        Selected group of external testers.
        <span v-if="selectedBuild.platform === 'ios'">(Tier 2)</span>
        <span v-if="selectedBuild.platform === 'android'">({{ AndroidReleaseTracks.TIER_2 }} track)</span>

        <br />

        All external testers.
        <span v-if="selectedBuild.platform === 'ios'">(Tier 3)</span>
        <span v-if="selectedBuild.platform === 'android'">({{ AndroidReleaseTracks.TIER_3 }} track)</span>
      </template>
    </p>

    <template v-if="readyForExternalTesting || readyForInternalTesting">
      <v-tooltip location="bottom" :disabled="!readyForInternalTesting">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            rounded
            class="mt-2"
            color="primary"
            variant="outlined"
            append-icon="mdi-arrow-right"
            :text="beginTestingText"
            :disabled="!isAppReleaseAdmin"
            @click="elevateToNextTestGroup(selectedBuild)"
          />

          <v-alert v-if="elevationError" type="error" :text="elevationError" />
        </template>

        <span>
          Internal testing from priority group begins automatically every 6 hours.
          <br />
          If you need to expedite the process, you may manually start internal testing.
        </span>
      </v-tooltip>
    </template>
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { releaseTierMeaning } from '#views/releases/constants'

  import { elevateRelease, releaseStatusColor, stepColor } from '#views/releases/utilities'

  import { AppStore } from '#stores'

  import { AndroidReleaseTracks, AppBuild, IosReleaseGroups, ReleaseStepStates, ReleaseTiers } from '#types'

  @Component({})
  class StepReleaseTier extends Vue {
    @Prop({ required: true }) public selectedBuild!: AppBuild

    @Prop({ required: true }) public releaseTier!: ReleaseTiers

    @Prop({ required: true }) private nextStep!: number

    @Prop({ default: false }) private isComplete!: boolean

    public elevationError = ''

    public readonly ReleaseTiers = ReleaseTiers
    public readonly releaseTierMeaning = releaseTierMeaning
    public readonly AndroidReleaseTracks = AndroidReleaseTracks

    private readonly appStore = new AppStore()

    public get isActive() {
      if (this.releaseTier === ReleaseTiers.PRIORITY_GROUP_TESTING) {
        return this.nextStep === ReleaseStepStates.INTERNAL_TESTING
      }

      if (this.releaseTier === ReleaseTiers.INTERNAL_TESTING) {
        return this.nextStep === ReleaseStepStates.EXTERNAL_TESTING
      }

      return this.nextStep === ReleaseStepStates.RELEASE_NOTES
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public get readyForInternalTesting() {
      return (
        this.releaseTier === ReleaseTiers.PRIORITY_GROUP_TESTING && this.nextStep === ReleaseStepStates.INTERNAL_TESTING
      )
    }

    public get readyForExternalTesting() {
      return (
        (this.releaseTier === ReleaseTiers.INTERNAL_TESTING && this.nextStep === ReleaseStepStates.EXTERNAL_TESTING) ||
        (this.selectedBuild.tier === ReleaseTiers.SELECTED_EXTERNAL_TESTING &&
          this.releaseTier === ReleaseTiers.FULL_EXTERNAL_TESTING &&
          this.nextStep === ReleaseStepStates.RELEASE_NOTES)
      )
    }

    public get dotColor() {
      let tier = ReleaseTiers.FULL_EXTERNAL_TESTING
      let targetStep = ReleaseStepStates.RELEASE_NOTES

      if (this.releaseTier === ReleaseTiers.PRIORITY_GROUP_TESTING) {
        tier = ReleaseTiers.PRIORITY_GROUP_TESTING
        targetStep = ReleaseStepStates.INTERNAL_TESTING
      }

      if (this.releaseTier === ReleaseTiers.INTERNAL_TESTING) {
        tier = ReleaseTiers.INTERNAL_TESTING
        targetStep = ReleaseStepStates.EXTERNAL_TESTING
      }

      return stepColor(this.nextStep, this.isComplete, targetStep, tier)
    }

    public get tierBackgroundColor() {
      if (!this.isActive) {
        return 'transparent'
      }

      if (this.releaseTier === ReleaseTiers.PRIORITY_GROUP_TESTING) {
        return releaseStatusColor(ReleaseTiers.PRIORITY_GROUP_TESTING)
      }

      if (this.releaseTier === ReleaseTiers.INTERNAL_TESTING) {
        return releaseStatusColor(ReleaseTiers.INTERNAL_TESTING)
      }

      return releaseStatusColor(ReleaseTiers.FULL_EXTERNAL_TESTING)
    }

    public get beginTestingText() {
      if (this.readyForInternalTesting) {
        return 'Begin internal testing'
      } else if (this.readyForExternalTesting && this.selectedBuild.tier === ReleaseTiers.SELECTED_EXTERNAL_TESTING) {
        return 'Full external testing'
      }

      return 'Begin external testing'
    }

    private get testGroups() {
      const groups = this.selectedBuild.platform === 'ios' ? IosReleaseGroups : AndroidReleaseTracks

      if (this.readyForInternalTesting) {
        return [groups.TIER_1]
      }

      if (this.readyForExternalTesting && this.selectedBuild.tier === ReleaseTiers.SELECTED_EXTERNAL_TESTING) {
        return [groups.TIER_3]
      }

      return [groups.TIER_2, groups.TIER_3]
    }

    public async elevateToNextTestGroup(build: AppBuild) {
      const { version, buildVersion, buildId, platform } = build

      if (!version || !platform || (platform === 'android' && !buildVersion) || (platform === 'ios' && !buildId)) {
        this.elevationError = `Tier elevation not possible, Invalid build data. Version: ${version} | platform ${platform} | Build: ${buildVersion}`

        return
      }

      const confirm = await this.$confirm(
        `${this.beginTestingText}?`,
        `${platform} | Version: ${version} | Build: ${buildVersion}`,
      )

      if (confirm) {
        await elevateRelease({
          releaseGroupsOrTracks: this.testGroups,
          buildId,
          buildVersion,
          platform,
          version,
        })
      }
    }
  }

  export default toNative(StepReleaseTier)
</script>
