<template>
  <v-dialog v-model="isOpen">
    <v-card>
      <v-card-title class="align-center">
        Feature flag rollouts

        <v-spacer />
        <!--
        <v-select
          v-model="calendarViewType"
          hide-details
          class="ma-2"
          label="View Mode"
          variant="outlined"
          style="max-width: 500px"
          :items="calendarViewTypes"
        /> -->

        <v-tooltip location="top" max-width="300px">
          <template #activator="{ props }">
            <v-icon v-bind="props" size="extra-small" color="primary">mdi-information-outline</v-icon>
          </template>

          <p>
            On the calendar, red dot means first step rollout and blue dot means advancement to next step in the
            rollout.
          </p>
        </v-tooltip>

        <v-btn icon="mdi-close" color="primary" class="ml-2" @click="close()" />
      </v-card-title>

      <v-card-text>
        <div class="px-4 pb-6">
          <v-row class="mt-n4 mb-4 align-center">
            <v-col cols="9">
              <div class="text-h6 font-weight-light">
                Currently active feature flags: {{ activeFeatures.length }} / {{ allFeatures.length }}
                <span class="mx-4">|</span>
                Currently active rollouts: {{ filteredOverrides.length }} / {{ totalOverridesCount }}
              </div>
            </v-col>

            <v-col cols="3">
              <v-switch
                v-model="showOnlyProdRollouts"
                style="justify-content: start"
                label="Show only production rollouts"
              />
            </v-col>
          </v-row>

          <v-alert v-if="showOnlyProjectRollouts" type="info">
            <v-row>
              <v-col class="flex-grow-1">
                <p>{{ `Viewing rollouts for project: ${project?.name}.` }}</p>
              </v-col>

              <v-col class="flex-shrink-1 text-right">
                <v-btn text="Show all rollouts" color="primary" @click="showAllClick()" />
              </v-col>
            </v-row>
          </v-alert>

          <v-sheet fill-height>
            <v-calendar
              v-model="calendarValue"
              color="primary"
              :events="rolloutCalendarEvents"
              :view-mode="calendarViewType"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            />
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Rollout_Stage, Rollout_Stage_StageType } from '@jouzen/feature-mgmt-api/rollout'

  import { calendarViewTypes } from '#views/features/constants'

  import {
    featureOverrideState,
    fixedPointToString,
    forEachFeatureOverride,
    overrideRolloutStatus,
  } from '#views/features/utilities'

  import { FeaturesStore, ProjectsStore } from '#stores'

  import { CalendarViewType, Feature, Override, Reference } from '#types'

  @Component({})
  class FeaturesState extends Vue {
    @Model({ default: false }) public isOpen!: boolean

    public calendarValue: Date[] = [new Date()]

    public calendarViewType: CalendarViewType = 'month'

    public readonly stageTypes = Rollout_Stage_StageType

    public readonly calendarViewTypes = calendarViewTypes

    private readonly featuresStore = new FeaturesStore()
    private readonly projectStore = new ProjectsStore()

    public showOnlyProjectRollouts = true
    public showOnlyProdRollouts = true

    public filteredOverrides: Reference[] = []

    public get allFeatures() {
      return this.featuresStore.features
    }

    public get project() {
      return this.projectStore.project
    }

    public get activeFeatures() {
      return this.allFeatures.filter((feature: Feature) => {
        let isActive = false

        forEachFeatureOverride(feature, (override: Override) => {
          if (
            override.rolloutOneOf?.$case === 'rollout' &&
            override.rolloutOneOf.rollout.stages.some((stage: Rollout_Stage) => {
              return stage.scheduledAt
            })
          ) {
            isActive = true
          }
        })

        return isActive
      })
    }

    public get activeOverrides() {
      const overrides: Reference[] = []

      this.allFeatures.forEach((feature: Feature) => {
        forEachFeatureOverride(feature, (override) => {
          if (
            overrideRolloutStatus(override) !== 'DISABLED' &&
            overrideRolloutStatus(override) !== 'INACTIVE' &&
            overrideRolloutStatus(feature, 'disable') === 'INACTIVE'
          ) {
            overrides.push({ feature, overrides: [override] })
          }
        })
      })

      return overrides
    }

    @Watch('isOpen')
    public isOpenChange() {
      if (this.isOpen) {
        this.showOnlyProjectRollouts = !!this.project
        this.filterOverrides()
      } else {
        // reset filters when dialog is closed
        this.showOnlyProjectRollouts = true
        this.showOnlyProdRollouts = true
      }
    }

    @Watch('showOnlyProdRollouts')
    public showOnlyProductionRolloutsChange() {
      this.filterOverrides()
    }

    @Watch('showOnlyProjectRollouts')
    public showOnlyProjectRolloutsChange() {
      this.filterOverrides()
    }

    public showAllClick() {
      // when show all rollouts button is clicked, hide the alert
      this.showOnlyProjectRollouts = false
    }

    public filterOverrides() {
      let baseList = this.activeOverrides

      // apply project filter if project is selected and showOnlyProjectRollouts is enabled
      if (this.project && this.showOnlyProjectRollouts) {
        baseList = baseList.filter((o: Reference) => {
          return o.feature?.metadata?.informative?.labels?.project === this.project.id
        })
      }

      // Apply production rollouts filter if enabled
      if (this.showOnlyProdRollouts) {
        baseList = baseList.filter((o: Reference) => {
          return (
            o.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('percentage-release') ||
            o.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('percentage-ouranians') ||
            o.overrides[0]?.metadata?.informative?.labels?.template?.startsWith('experiment-')
          )
        })
      }

      this.filteredOverrides = baseList
    }

    public get totalOverridesCount() {
      return this.allFeatures.reduce((acc: number, feature: Feature) => {
        forEachFeatureOverride(feature, () => {
          acc++
        })

        return acc
      }, 0)
    }

    public get rolloutCalendarEvents() {
      const rolloutCalendarEvents = this.filteredOverrides
        .flatMap((r: Reference) => {
          const stages: Rollout_Stage[] =
            (r.overrides[0].rolloutOneOf?.$case === 'rollout' && r.overrides[0].rolloutOneOf.rollout.stages) || []

          let scheduledAt = this.$dayjs(stages[0].scheduledAt)

          return stages
            .map((_rolloutStage, stageIndex) => {
              let stageDuration = 0

              if (stageIndex > 0) {
                if (stages.slice(0, stageIndex).every((s: any) => s.duration)) {
                  stageDuration = this.$dayjs.duration(parseInt(stages[stageIndex - 1].duration!.seconds), 's').asDays()

                  scheduledAt = scheduledAt.add(stageDuration, 'day')
                } else {
                  return null
                }
              }

              const eventTime = this.$dayjs(scheduledAt).format('HH:mm')

              const eventTitle = this.getCalendarEventTitle(r.overrides[0], stageIndex)

              return {
                title: `${eventTime}: ${r.feature.metadata?.name.toUpperCase()} - ${eventTitle}`,
                start: scheduledAt.toDate(),
                end: scheduledAt.toDate(),
                color: stageIndex === 0 ? 'red' : 'blue',
                allDay: false,
              }
            })
            .filter(Boolean)
        })
        .sort((a, b) => a!.start.getTime() - b!.start.getTime())

      return rolloutCalendarEvents
    }

    @Emit('close')
    public close() {
      this.isOpen = false

      // reset filters
      this.showOnlyProjectRollouts = true
      this.showOnlyProdRollouts = true
    }

    private getCalendarEventTitle(override: Override, stageIndex: number) {
      const rolloutType = override?.metadata?.informative?.labels?.template?.split('-')[0]

      const rolloutStage = ((override.rolloutOneOf?.$case === 'rollout' && override.rolloutOneOf.rollout.stages) || [])[
        stageIndex
      ]

      const featureStatus = featureOverrideState(override) !== 'DISABLED' ? 'Enable' : 'Disable'

      if (rolloutType === 'percentage') {
        const overrideName = override.metadata!.uid!.split('_').slice(0, -1).join('_')

        const days = rolloutStage.duration
          ? this.$dayjs.duration(parseInt(rolloutStage.duration.seconds), 's').asDays()
          : 0

        const stageDuration =
          rolloutStage.type === Rollout_Stage_StageType.ACTUATED
            ? 'paused'
            : rolloutStage.type === Rollout_Stage_StageType.FINALIZING
              ? 'infinite'
              : `${days} ${days === 1 ? 'day' : 'days'}`

        const stagePercentile = fixedPointToString(rolloutStage.percentile)

        return `${overrideName.toUpperCase()} - Step ${stageIndex + 1}: ${featureStatus} for ${stagePercentile}% (${stageDuration})`
      } else {
        const groupName =
          override.metadata?.informative?.displayName || `Group ${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[stageIndex]}`

        const lowerPercentile = fixedPointToString(
          override.criteria?.oneOf?.$case === 'and' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.lowerBound,
        )

        const upperPercentile = fixedPointToString(
          override.criteria?.oneOf?.$case === 'and' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.$case === 'user' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf?.user.oneOf?.$case === 'range' &&
            override.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate?.oneOf.user.oneOf.range.upperBound,
        )

        const enableFor = `${lowerPercentile}% - ${upperPercentile}%`

        return `${groupName.toUpperCase()} - Enable for ${enableFor}`
      }
    }
  }

  export default toNative(FeaturesState)
</script>

<style lang="scss" scoped>
  :deep(.v-calendar) {
    .v-chip {
      padding: 12px !important;
      margin-bottom: 4px !important;
      border-radius: unset !important;
    }

    .v-chip__content {
      display: block;
      overflow: scroll;
      color: rgb(33 150 243);
    }

    .v-chip.v-chip--density-comfortable {
      height: auto !important;
      text-wrap: wrap;
    }

    .v-calendar-weekly__day-label .v-btn {
      margin-top: 8px;
      margin-bottom: -12px;
      color: white !important;
      background-color: rgb(200 200 200) !important;
    }

    .v-calendar-weekly__day-label .v-btn.v-calendar-weekly__day-label__today {
      color: white !important;
      background-color: rgb(33 150 243) !important;
    }

    .v-chip--variant-tonal .v-chip__underlay {
      background-color: rgba(33, 150, 243, 0.5) !important;
    }
  }

  :deep(.v-switch) {
    .v-selection-control {
      justify-content: end;
    }
  }
</style>
