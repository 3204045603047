<template>
  <div class="pt-6">
    <v-table>
      <tr>
        <td />
        <td>
          <!-- eslint-disable vue/no-v-html -->
          <div
            style="word-wrap: break-word"
            v-html="`<p>${feature.metadata?.informative?.description.replace(/\n/gi, '</p><p>')}</p>`"
          />

          <div
            v-if="feature.metadata?.informative?.referenceUrls && feature.metadata.informative?.referenceUrls[0]"
            class="mt-4"
          >
            <a :href="feature.metadata.informative.referenceUrls[0]" class="text-blue" target="_blank">More info</a>
          </div>
        </td>
        <td>
          <div class="text-truncate text-no-wrap">
            {{ getProjectTeam(feature) || 'No team info' }}
          </div>
          <br />
          <div class="text-truncate text-no-wrap">
            {{ feature.metadata?.informative?.labels.contact || 'No contact info' }}
          </div>
        </td>
        <td>
          <div v-for="(parameter, i) in feature.parameters.filter((p) => !p.deprecated)" :key="i">
            <v-row class="justify-space-between mb-3" style="border-bottom: 1px dotted rgba(0, 0, 0, 0.3); margin: 0">
              <v-col cols="6" style="padding: 0; word-wrap: break-word">
                {{ parameter.name }}
              </v-col>
              <v-col cols="6" class="text-right" style="padding: 0; word-wrap: break-word">
                {{ transformParamValue((parameter.type!.oneOf as any)[parameter.type!.oneOf!.$case].defaultValue) }}
              </v-col>
            </v-row>
          </div>
        </td>
        <td class="text-right pr-8">
          <div>Created</div>
          <br />

          {{
            feature.metadata?.changeRecord?.createdAt
              ? $dayjs(feature.metadata?.changeRecord?.createdAt).format('DD MMM YYYY')
              : ''
          }}
        </td>
      </tr>
    </v-table>

    <div class="d-flex flex-column flex-sm-row mx-2 my-4">
      <StatisticsChip
        class="ml-10"
        stat="count"
        unit="users"
        label="Enabled for"
        :loading="isLoading"
        :stats-data="featureStats"
        @open="statisticsChartRef.open()"
      />

      <StatisticsChart
        ref="statisticsChartRef"
        unit="day"
        title="Active users with feature flag enabled"
        :loading="isLoading"
        :header="feature.metadata?.name?.toUpperCase()"
        :guides="featureEvents"
        :stats-data="featureStats"
        :labels="['Users with flag enabled', 'Users with flag enabled current day']"
      />

      <v-spacer />

      <v-btn
        class="mr-2"
        color="primary"
        prepend-icon="mdi-comment-outline"
        :text="(notesCount ? notesCount + ' ' : '') + (notesCount === 1 ? 'Note' : 'Notes')"
        @click="emitNotes()"
      />

      <v-tooltip location="top end" :disabled="isProjectEditor(feature)">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mr-2"
            text="Modify"
            color="primary"
            prepend-icon="mdi-pencil-outline"
            :disabled="!isProjectEditor(feature)"
            @click="emitEdit()"
          />
        </template>
        <div>Request access from the project owner</div>
        {{ (ffOwningProject && ffOwningProject.owner) || '' }}
      </v-tooltip>

      <!-- <v-tooltip
        v-if="!feature.metadata.objectType"
        location="top end"
        :disabled="feature.metadata.owners === userEmail"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            class="mr-2"
            text="Withdraw"
            prepend-icon="mdi-trash-can-outline"
            :disabled="true"
            @click="withdrawFeature()"
          />
        </template>
        <div>Only owner can withdraw this</div>
        {{ feature.metadata.owners }}
      </v-tooltip> -->

      <!-- <v-tooltip v-if="!feature.metadata.objectType" location="top end" :disabled="isAppFeatureAdmin">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mr-2"
            color="green"
            text="Accept"
            prepend-icon="mdi-check"
            :disabled="true"
            @click="acceptFeature()"
          />
        </template>
        <div>Only system admins can accept this</div>
      </v-tooltip> -->

      <v-tooltip location="top end" :disabled="!isProjectEditor(feature)">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            class="mr-2"
            text="Deprecate"
            prepend-icon="mdi-trash-can-outline"
            :disabled="!!feature.overrideList.length"
            @click="deprecateFeature()"
          />
        </template>
        <div>Only feature admins can deprecate this</div>
      </v-tooltip>

      <v-btn class="mr-2" color="green" text="Rollouts" prepend-icon="mdi-rocket-launch" @click="emitRollouts()" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Feature_Phase } from '@jouzen/feature-mgmt-api/feature'

  import { fixedPointToString, forEachFeatureOverride } from '#views/features/utilities'
  import { getProjectKey, getProjectTeam, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore } from '#stores'

  import { Dialog, Feature } from '#types'

  @Component({})
  class FeatureDrawer extends Vue {
    @Prop() public feature!: Feature

    public notesCount = 0

    public readonly getProjectTeam = getProjectTeam
    public readonly isProjectEditor = isProjectEditor

    private readonly appStore = new AppStore()

    private readonly featuresStore = new FeaturesStore()
    private readonly projectsStore = new ProjectsStore()

    @Ref() public readonly statisticsChartRef!: Dialog

    private flagNotesUnsubscribe: Unsubscribe | null = null

    public get userEmail() {
      return this.appStore.email
    }

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get featureStats() {
      if (this.featuresStore.stats?.seriesLabels) {
        const name = `${getProjectKey(this.feature)}/${this.feature.metadata?.name}`

        const index = this.featuresStore.stats?.seriesLabels.findIndex((item: any) => item[1] === name)

        if (index > -1) {
          const seriesForId = this.featuresStore.stats?.series[index]

          return { xValues: this.featuresStore.stats?.xValues, series: seriesForId }
        }
      }

      return null
    }

    public get featureEvents() {
      const events: any[] = []

      if (this.featureStats) {
        forEachFeatureOverride(this.feature, (override) => {
          if (override.rolloutOneOf?.$case === 'rollout') {
            override.rolloutOneOf?.rollout?.stages.forEach((s: any, i: number) => {
              if (
                s.scheduledAt &&
                this.$dayjs(s.scheduledAt) > this.$dayjs(this.featureStats!.xValues[0]).add(1, 'day')
              ) {
                events.push({
                  label: override.metadata?.uid?.split('_').slice(0, -1).join('_').toUpperCase() + ' - Step ' + (i + 1),
                  value: this.$dayjs(s.scheduledAt).valueOf(),
                })
              }
            })
          }
        })
      }

      return events
    }

    public get ffOwningProject() {
      return this.projectsStore.projects.find((p) => p.id === this.feature.metadata?.informative?.labels.project)
    }

    public get isAppFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin
    }

    @Emit('edit')
    public emitEdit() {
      return this.feature
    }

    @Emit('notes')
    public emitNotes() {
      return this.feature
    }

    @Emit('rollouts')
    public emitRollouts() {
      return this.feature
    }

    @Watch('feature', { immediate: true })
    protected featureChanged(val: any, oldVal: any) {
      if (val?.metadata.name !== oldVal?.metadata.name) {
        if (this.flagNotesUnsubscribe) {
          this.flagNotesUnsubscribe()
        }

        this.flagNotesUnsubscribe = onSnapshot(
          query(collection(getFirestore(), `/features/${this.feature.metadata?.name}/notes`)),
          (snap) => {
            this.notesCount = snap.docs.length
          },
        )
      }
    }

    public beforeUnmount() {
      if (this.flagNotesUnsubscribe) {
        this.flagNotesUnsubscribe()

        this.flagNotesUnsubscribe = null
      }
    }

    public deprecateFeature() {
      this.$confirm(
        'Confirm deprecate following feature flag?',
        getProjectKey(this.feature) + '/' + this.feature.metadata?.name,
        { buttonTrueColor: 'error' },
      ).then((confirmed) => {
        if (confirmed) {
          this.feature.phase = Feature_Phase.DEPRECATED

          this.featuresStore.updateFeature(this.feature)
        }
      })
    }

    public transformParamValue(value: any) {
      if (typeof value === 'object' && value.sign !== undefined) {
        return fixedPointToString(value)
      } else if (typeof value === 'object' && value.seconds !== undefined) {
        return value.seconds
      }

      return value
    }
  }

  export default toNative(FeatureDrawer)
</script>
