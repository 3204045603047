<template>
  <OuraHeader
    :texts="[
      'Welcome to a place where features come to life',
      '...and sometimes some hearth warming fires...',
      'Waltari Content Editor — Home of the content',
    ]"
  />

  <div class="page">
    <div class="text-primary text-h5">Welcome to the content editor</div>

    <div class="px-2 py-6">
      <div class="text-body-1">
        Waltari's end goal is to make managing features, insights and APP / OTA releases a breeze
        <br />
        (not a cold breeze more like cool but still warm, you know what we mean, right?).
        <br />
        <br />
        <div class="text-h6">Access rights</div>
        <br />
        Everyone has a view access and their own workspace, for more rights make a request through the
        <br />
        <a
          href="https://oura.service-now.com/sp?id=sc_category&sys_id=cceddaac1bfe511080b3ea41604bcbac&catalog_id=e0d08b13c3330100c8b837659bba8fb4&spa=1"
          target="_blank"
          class="text-info text-no-wrap text-decoration-none"
        >
          IT Service Portal.
        </a>

        <br />
      </div>
    </div>

    <div class="text-primary text-h5 my-4">Whats new in Waltari</div>

    <div ref="latestChanges" class="px-2">
      <Changelog class="changelog" style="overflow: hidden" :style="{ 'max-height': changelogHeight + 'px' }" />
    </div>

    <div class="mb-4 px-2" style="overflow: hidden">
      <v-expand-transition>
        <Changelog v-show="fullChangelog" class="changelog" :style="{ 'margin-top': '-' + changelogHeight + 'px' }" />
      </v-expand-transition>
    </div>

    <div class="text-caption px-4" style="cursor: pointer" @click="fullChangelog = !fullChangelog">
      <span v-if="!fullChangelog">Show older releases</span>
      <span v-else>Hide older releases</span>
    </div>
  </div>
</template>

<script lang="ts">
  import Changelog from '../CHANGELOG.md'

  import { Component, Ref, Vue, toNative } from 'vue-facing-decorator'

  import { OuraHeader } from '@jouzen/outo-toolkit-vuetify'

  @Component({
    components: {
      Changelog,
      OuraHeader,
    },
  })
  class Home extends Vue {
    public changelogHeight = 0

    public fullChangelog = false

    @Ref() private readonly latestChanges: HTMLElement | null = null

    public mounted() {
      const element = this.latestChanges!.querySelectorAll('h1')[1]

      if (element) {
        this.changelogHeight = element.getBoundingClientRect().y - this.latestChanges!.getBoundingClientRect().y
      }
    }
  }

  export default toNative(Home)
</script>

<style lang="scss" scoped>
  @use 'sass:map';
  @use 'vuetify/settings' as v;

  .page {
    position: relative;
    margin-top: -50px;
    z-index: 1;
    background: rgb(var(--v-theme-background));
    padding: 50px 96px;
  }

  :deep(.changelog) {
    h1 {
      a {
        color: rgb(var(--v-theme-foreground));
        font-weight: bold;
        text-decoration: unset;
      }
    }

    h2 {
      color: rgb(var(--v-theme-foreground));
      font-weight: normal;
      padding: 8px 0 0 0;
    }
  }

  @media #{map.get(v.$display-breakpoints, 'sm-and-down')} {
    .page {
      padding: 40px;
    }
  }
</style>
