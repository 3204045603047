<template>
  <div class="pa-4">
    <v-progress-linear color="white" :absolute="false" :model-value="75" :indeterminate="false" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentContributor } from '#types'

  @Component({})
  class ContributorPreview extends Vue {
    @Prop() public preview!: boolean
    @Prop() public category!: string
    @Prop() public component!: UIComponentContributor
  }

  export default toNative(ContributorPreview)
</script>
