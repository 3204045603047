<template>
  <v-data-table
    v-if="!!override?.parameters"
    class="mt-8"
    hide-default-footer
    no-data-text="No extra parameters configured"
    :headers="parametersHeaders"
    :items="feature.parameters.filter((p) => !p.deprecated)"
    :items-per-page="1000"
  >
    <template #item.icon="{ item }">
      <v-tooltip
        :text="
          item.name === 'enabled' || item.visibility === AnalyticsVisibility.EXPORTED
            ? 'Tracked in Amplitude'
            : 'Not tracked in Amplitude'
        "
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            :color="item.name === 'enabled' || item.visibility === AnalyticsVisibility.EXPORTED ? 'primary' : 'grey'"
          >
            mdi-google-analytics
          </v-icon>
        </template>
      </v-tooltip>
    </template>

    <template #item.description="{ item }">
      {{ capitalize(item.description) }}
    </template>

    <template #item.name="{ item }">
      {{ item.name.toUpperCase() }}
    </template>

    <template #item.default="{ item }">
      {{ transformParamValue((item.type?.oneOf as any)[item.type!.oneOf!.$case].defaultValue) }}
    </template>

    <template #item.override="{ item }">
      <span
        :class="
          override.parameters[item.name] &&
          transformParamValue((item.type?.oneOf as any)[item.type!.oneOf!.$case].defaultValue) !==
            transformParamValue((override.parameters[item.name].oneOf as any)[item.type!.oneOf!.$case])
            ? 'font-weight-bold'
            : ''
        "
      >
        {{
          override.parameters[item.name]
            ? transformParamValue((override.parameters[item.name].oneOf as any)[item.type!.oneOf!.$case])
            : transformParamValue((item.type?.oneOf as any)[item.type!.oneOf!.$case].defaultValue)
        }}
      </span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
  import { capitalize } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Parameter_AnalyticsVisibility } from '@jouzen/feature-mgmt-api/parameter'

  import { parametersHeaders } from '#views/features/constants'

  import { fixedPointToString } from '#views/features/utilities'

  import { Feature, Override, RolloutTarget } from '#types'

  @Component({})
  class StatusParams extends Vue {
    @Prop() public feature!: Feature
    @Prop() public override!: Override

    @Prop() public rolloutView!: RolloutTarget

    public readonly capitalize = capitalize

    public readonly parametersHeaders = parametersHeaders

    public readonly AnalyticsVisibility = Parameter_AnalyticsVisibility

    public transformParamValue(value: any) {
      if (typeof value === 'object' && value.sign !== undefined) {
        return fixedPointToString(value)
      } else if (typeof value === 'object' && value.seconds !== undefined) {
        return value.seconds
      }

      return value
    }
  }

  export default toNative(StatusParams)
</script>
