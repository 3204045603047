import { JobDetails, TranslationKeyDetails } from 'phrase-js'

import { capitalize } from 'lodash-es'

import { cloudFunction } from '#common/cloudFunctions.js'

import { releaseProjectJobTemplates } from '#views/releases/constants'

import {
  AppBuild,
  AppBuildTest,
  AppPlatform,
  AppReviewData,
  ElevateData,
  PhraseProjects,
  PlayStoreReleaseStatuses,
  ReleaseStates,
  ReleaseStepStates,
  ReleaseSteps,
  ReleaseTiers,
  TestRunOutcomes,
  TranslationKeyTemplate,
} from '#types'

const phraseJobTemplateId =
  releaseProjectJobTemplates[process.env.VITE_FIREBASE_PROJECT_ID === 'content-editor-production' ? 'prod' : 'dev']

/**
 * Determine if the tests for a specific commit have failures
 */
export const releaseTestsHaveFailures = (
  buildTests: Pick<AppBuildTest, 'testRunOutcome'>[] | null | undefined,
): boolean => {
  if (!buildTests) {
    return false
  }

  return Object.values(buildTests).some((test) => test.testRunOutcome === TestRunOutcomes.FAILURE)
}

export const releaseTestIconColor = (buildTests: Pick<AppBuildTest, 'testRunOutcome'>[]) => {
  if (buildTests.length === 0) {
    return 'grey'
  } else {
    return releaseTestsHaveFailures(buildTests) ? 'orange-darken-2' : 'green-darken-2'
  }
}

export const releaseTestIcon = (buildTests: Pick<AppBuildTest, 'testRunOutcome'>[]) => {
  if (buildTests.length === 0) {
    return 'mdi-minus'
  } else {
    return releaseTestsHaveFailures(buildTests) ? 'mdi-alert-outline' : 'mdi-check-circle'
  }
}

export const getReleaseCrashFreeRateFromSentry = async (selectedRelease: AppBuild) => {
  const { version, platform, createdAt } = selectedRelease || {}

  if (!version || !platform || !createdAt) {
    return undefined
  }

  const response = await cloudFunction<{ crashFreeRate: number }>('fetchCrashFreeRatesFromSentry', {
    releaseVersion: version,
    platform,
    start: utcDateAndTime(createdAt.toDate()),
    end: utcDateAndTime(new Date()),
  })

  return response?.crashFreeRate ? `${(response.crashFreeRate * 100).toString().slice(0, 6)} %` : 'Unknown'
}

export const maxReleaseNoteLength = (platform: AppPlatform) => {
  return platform === 'android' ? 500 : 1000
}

export const uploadAppReleaseNotes = async (keyId: string | undefined, platform: AppPlatform, appVersion: string) => {
  return await cloudFunction('uploadAppReleaseNotes', {
    keyId,
    platform,
    appVersion,
    project: PhraseProjects.RELEASE,
  })
}

export const requestReleaseNotesTranslations = async (
  platform: AppPlatform,
  version: string,
  translation: string,
  dueDate: Date,
) => {
  const translationKeyDetails = await cloudFunction<TranslationKeyDetails>('createNewLocaleKeysToPhrase', {
    project: PhraseProjects.RELEASE,
    data: {
      dataType: 'string',
      defaultTranslationContent: translation,
      maxCharactersAllowed: maxReleaseNoteLength(platform),
      name: `Release Notes ${capitalize(platform)} ${version}`,
    },
  })

  if (!translationKeyDetails?.id) {
    return undefined
  }

  return await cloudFunction<JobDetails>('createNewTranslateJobToPhrase', {
    project: PhraseProjects.RELEASE,
    data: {
      dueDate,
      jobTemplateId: phraseJobTemplateId,
      translationKeyIds: [translationKeyDetails.id],
      name: `${capitalize(platform)} ${version} what's new?`,
      briefing: `Translation for "What's new" section in ${platform === 'ios' ? 'App Store' : 'Google Play'}.`,
    },
  })
}

export const stepColor = (
  nextStep: number,
  isComplete: boolean,
  targetStep: number,
  tierOrState?: ReleaseTiers | ReleaseStates,
) => {
  if (nextStep > targetStep) {
    return 'success'
  } else if (nextStep === targetStep) {
    return tierOrState ? releaseStatusColor(tierOrState) : 'indigo-lighten-3'
  } else if (isComplete) {
    return 'green-lighten-3'
  } else {
    return 'grey-lighten-3'
  }
}

export const releaseStatusColor = (tierOrState: ReleaseTiers | ReleaseStates | undefined, lighter?: boolean) => {
  switch (tierOrState) {
    case ReleaseTiers.PRIORITY_GROUP_TESTING:
      return 'pink-lighten-' + (lighter ? '5' : '3')

    case ReleaseTiers.INTERNAL_TESTING:
      return 'blue-lighten-' + (lighter ? '5' : '3')

    case ReleaseTiers.SELECTED_EXTERNAL_TESTING:
    case ReleaseTiers.FULL_EXTERNAL_TESTING:
      return 'orange-lighten-' + (lighter ? '5' : '3')

    case ReleaseTiers.PRODUCTION:
      return 'red-lighten-' + (lighter ? '5' : '3')

    case ReleaseStates.PREPARE_FOR_SUBMISSION:
    case ReleaseStates.WAITING_FOR_REVIEW:
    case ReleaseStates.PENDING_FOR_RELEASE:
      return 'indigo-lighten-' + (lighter ? '5' : '3')

    default:
      return 'grey-lighten-' + (lighter ? '5' : '3')
  }
}

export const fetchReleaseNoteTemplateTranslations = async () => {
  return (
    (await cloudFunction<TranslationKeyTemplate[]>('fetchTranslationsFromPhrase', {
      project: PhraseProjects.RELEASE,
      keysQuery: 'tags:release_note_template',
    })) || []
  )
}

export const elevateRelease = async (data: ElevateData) => {
  return await cloudFunction('elevateAppTierInAppStore', data)
}

export const changeReviewStatus = async (action: 'submit' | 'cancel', data: AppReviewData) => {
  return await cloudFunction('modifyReviewStateInAppStore', { action, ...data })
}

export const adjustAndroidRollout = async (
  action: PlayStoreReleaseStatuses,
  data: { buildVersion: string; rolloutPercentage: number },
) => {
  return await cloudFunction('modifyRolloutStateInAppStore', { action, ...data })
}

export const syncReleaseStates = async () => {
  return await cloudFunction('manualUpdateFromAppStore', {})
}

/**
 * Get the next step in the release process.
 *
 * NOTE: Returned step is not the current step, but the next one.
 */
export const getNextStep = (stepState: ReleaseSteps) => {
  if (!stepState.technicalStatusIsValid) {
    return ReleaseStepStates.PRIORITY_GROUP_TESTING
  }

  if (!stepState.priorityGroupTesting) {
    return ReleaseStepStates.INTERNAL_TESTING
  }

  if (!stepState.internalTesting) {
    return ReleaseStepStates.EXTERNAL_TESTING
  }

  if (!stepState.externalTesting) {
    return ReleaseStepStates.RELEASE_NOTES
  }

  if (!stepState.releaseNotes) {
    return ReleaseStepStates.REVIEW
  }

  return ReleaseStepStates.ROLLOUT
}

/**
 * Get the date and time in the format: 2021-09-15T12:00:00
 *
 * @param date
 * @returns string
 */
export const utcDateAndTime = (date: Date) => {
  const year = date.getUTCFullYear()
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = date.getUTCDate().toString().padStart(2, '0')

  const hours = date.getUTCHours().toString().padStart(2, '0')
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
