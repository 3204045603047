<template>
  <v-dialog :model-value="true" persistent width="1200">
    <v-card>
      <v-card-title>Review changes to be released</v-card-title>

      <v-card-text style="min-height: 600px; max-height: 600px">
        <div v-if="project" class="mb-2">
          Content changes since last project release:
          {{
            (project?.releasedAt || project?.createdAt).toDate().toLocaleString('default', {
              hour: 'numeric',
              minute: 'numeric',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
          }}
        </div>

        <v-alert v-if="!loading && !changes.length && hasUnReleasedChanges" type="warning" class="mb-2">
          <v-col>
            <v-row align="center">
              No changes found in selected project since last release was made. Reset project changes timestamp?
              <v-spacer />

              <v-tooltip location="bottom">
                Last releasable change timestamp:
                {{
                  project?.changesAt?.toDate().toLocaleString('default', {
                    hour: 'numeric',
                    minute: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })
                }}
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      v-if="!loading && !changes.length && hasUnReleasedChanges"
                      color="primary"
                      text="Reset"
                      @click="resetChangesAt()"
                    />
                  </div>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-alert>

        <v-data-table
          v-model:expanded="expanded"
          show-expand
          expand-on-click
          disable-pagination
          hide-default-footer
          class="changes"
          item-value="key"
          no-data-text="No changes in selected project since last release was made"
          :loading="loading"
          :headers="headers"
          :items="changes"
          :items-per-page="1000"
          :sort-by="[{ key: 'id', order: 'asc' }]"
          :group-by="[{ key: 'Page', order: 'asc' }]"
        >
          <template #item.id="{ item }">
            {{ item.id }}
          </template>

          <template #item.action="{ item }">
            {{ getChangeAction(item) }}
          </template>

          <template #item.changes="{ item }">
            {{ item.changes.length }}
          </template>

          <template #group-header="{ item, toggleGroup, isGroupOpen }">
            <tr>
              <td class="bg-grey-lighten-5 px-1" :colspan="headers.length + 1">
                <v-btn
                  size="large"
                  rounded="0"
                  class="d-flex justify-space-between"
                  style="width: 100%"
                  :text="item.value + ' (' + item.items.length + ')'"
                  @click="toggleGroup(item)"
                >
                  <template #append>
                    <v-icon class="ml-2">
                      {{ !isGroupOpen(item) ? 'mdi-plus' : 'mdi-minus' }}
                    </v-icon>
                  </template>
                </v-btn>
              </td>
            </tr>
          </template>

          <template #expanded-row="{ item }">
            <td v-if="expanded.includes(item.key)" :colspan="headers.length" style="padding-left: 75px">
              <div v-for="(change, index) in item.changes" :key="index" style="max-width: 870px; margin-right: -50px">
                <v-divider class="mb-5" />

                <div class="d-flex flex-row align-baseline">
                  <div v-if="change.author">
                    <span class="overline">
                      {{ change.author.displayName || change.author.email || 'Unknown author' }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="overline">Unknown author (maybe a script?)</span>
                  </div>

                  <v-spacer />

                  <span class="oberline">
                    {{
                      change.timestamp.toLocaleString('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })
                    }}
                  </span>
                </div>

                <blockquote v-if="change.author" class="blockquote">
                  {{ change.author.comment }}
                </blockquote>

                <DiffView :diff="change.diff" />
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="expanded.length > 0" text="Collapse All" @click="expanded = []" />
        <v-btn v-else text="Expand All" @click="expanded = [...changes.map((c) => c.key)]" />

        <v-spacer />

        <v-btn text="Cancel" @click="cancel()" />
        <v-btn
          text="Continue"
          color="primary"
          :disabled="loading || !project || !changes.length"
          @click="confirmChanges()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import slug from 'slug'

  import { groupBy, isEmpty, isEqual, upperFirst } from 'lodash-es'

  import { Component, Emit, mixins, toNative } from 'vue-facing-decorator'

  import { getApp } from 'firebase/app'
  import { collectionGroup, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { JsonDiff } from '@jouzen/outo-toolkit-vuetify'

  import { changesHeaders, ignoreReleaseKeys } from '#views/rollouts/constants'

  import { insightsMigrationMap, translationsMigrationMap } from '#views/insights/migrations'

  import { ProjectsStore, RolloutsStore } from '#stores'

  @Component({})
  class ChangesDialog extends mixins(JsonDiff) {
    @Emit('cancel')
    public cancel() {
      return null
    }

    @Emit('confirm')
    public confirm(data: any) {
      return data
    }

    public loading = true

    public project: any = null
    public release: any = null

    public changes: any[] = []
    public expanded: any[] = []

    public projects: string[] = []

    public readonly headers = changesHeaders

    private readonly projectsStore = new ProjectsStore()
    private readonly rolloutsStore = new RolloutsStore()

    public get activeProject() {
      return this.projectsStore.project
    }

    public get changedProjects() {
      return this.projectsStore.projects.filter((p) => this.activeProject?.id === p.id || this.projects.includes(p.id))
    }

    public mounted() {
      this.project = this.activeProject || this.changedProjects[0] || ''

      this.updateChanges()
    }

    public confirmChanges() {
      this.confirm({
        project: this.project?.id,
        changes: this.changes,
      })
    }

    public getChangeAction(item: any) {
      let action = 'edited'

      if (item.type !== 'translations') {
        for (const change of item.changes) {
          if (change.action === 'deleted') {
            return 'deleted'
          } else if (change.action === 'created') {
            return 'created'
          }
        }
      }

      return action
    }

    public async updateChanges() {
      this.loading = true

      const changes: any[] = []

      await this.rolloutsStore.listOtaRollouts('insight_content')

      const date = this.project?.releasedAt || this.project?.createdAt

      this.project = this.activeProject || this.changedProjects[0] || null

      this.release = (await getDoc(doc(getFirestore(), 'rollouts/latest'))).data()

      if (await this.checkContentChanges(date, changes)) {
        await this.checkLocalesChanges(date, changes)

        this.changes = Object.entries(groupBy(changes, (o) => o.type + '-' + o.id)).map(([_k, v]) => ({
          id: v[0].id,
          rid: v[0].rid,
          key: [v[0].type, v[0].id].join('-'),
          type: v[0].type,
          Page: upperFirst(v[0].type),
          state: v[v.length - 1]?.state,
          changes: v,
        }))
      }

      this.loading = false
    }

    public async resetChangesAt() {
      await this.projectsStore.resetChangesAtTimestamp(this.project)
      this.updateChanges()
    }

    public get hasUnReleasedChanges(): boolean {
      return (
        !!this.project?.changesAt &&
        (this.project?.releasedAt || this.project.createdAt).toDate() < this.project?.changesAt?.toDate()
      )
    }

    private async checkContentChanges(date: any, changes: any[]) {
      const parentStates: any = {}

      const histories = await getDocs(
        query(
          collectionGroup(getFirestore(), 'history'),
          where('timestamp', '>', date),
          where('projectId', '==', this.project.id),
        ),
      )

      if (histories.docs.length >= 2500) {
        console.error(
          'Too many changes',
          date,
          this.project.id,
          histories.docs.map((d) => d.ref.path),
        )

        return false
      }

      for (const history of histories.docs) {
        const data = history.data()

        const id = history.ref.path.split('/')[1]
        const type = history.ref.path.split('/')[0]

        let state = data.after?.state || data.before?.state
        let parentState = data.after?.state || data.before?.state

        const author = data?.after?.author || data?.before?.author
        const project = data?.after?.project || data?.before?.project
        const projects = data?.after?.projects || data?.before?.projects

        if (history.ref.path.includes('/messages/')) {
          parentState =
            parentStates[`${type}/${id}`] || (await getDoc(doc(getFirestore(), `${type}/${id}`))).data()?.state
        }

        parentStates[`${type}/${id}`] = parentState

        if (state !== 'draft' && parentState !== 'draft' && type !== 'features' && type !== 'templates') {
          ignoreReleaseKeys.forEach((key) => {
            if (data?.after) {
              delete data.after[key]
            }

            if (data?.before) {
              delete data.before[key]
            }
          })

          const diff = this.getDiff(
            isEmpty(data.before) ? null : data.before,
            isEmpty(data.after) ? null : data.after,
            history.ref.path,
          )

          if ((diff?.split('\n')?.length || 0) > 6) {
            changes.push({
              id: insightsMigrationMap[id] || id,
              rid: id,
              key: [type, insightsMigrationMap[id] || id].join('-'),
              diff,
              type,
              state,
              author,
              project,
              projects,
              timestamp: data.timestamp.toDate(),
              action: !data.before?.id ? 'created' : !data.after?.id ? 'deleted' : 'edited',
            })

            this.projects.push(data.projectId)
          }
        }
      }

      return true
    }

    private async checkLocalesChanges(date: any, changes: any[]) {
      const functions = getFunctions(getApp(), 'europe-west1')

      const response: any = await httpsCallable(
        functions,
        'exportTranslationsFromPhrase',
      )({ projectTag: slug(this.project.name, '_'), releaseTime: this.$dayjs(date.toDate()).valueOf() })

      const locales = await getDocs(query(collectionGroup(getFirestore(), 'locales')))

      const localesData: any = {}

      for (const locale of locales.docs) {
        localesData[locale.id] = { ...localesData[locale.id], ...locale.data() }
      }

      for (const translations of response?.data?.translations || []) {
        for (const translation of translations.data || []) {
          Object.entries(translation.locales || {}).forEach(([localeKey, localeMessage]) => {
            const insightId = localeKey.split(':')[0]

            const localesDataAfter: any = []
            const localesDataBefore: any = []

            localesDataAfter.push({
              message: localeMessage,
              language: translation.language,
            })

            localesDataBefore.push({
              message: (localesData[translation.language] && localesData[translation.language][localeKey]) || '',
              language: translation.language,
            })

            if (!isEqual(localesDataBefore, localesDataAfter)) {
              changes.push({
                id: translationsMigrationMap[localeKey] || insightId,
                key: 'translations-' + translationsMigrationMap[localeKey] || insightId,
                type: 'translations',
                tags: [translations.collection],
                state: localesDataAfter.some((t: any) => !t.message) ? 'in progress' : 'completed',
                author: { email: 'phrase@ouraring.com' },
                localeKey: localeKey,
                translations: localesDataAfter,
                timestamp: new Date(),
                diff: this.getDiff(
                  isEmpty(localesDataBefore) ? null : localesDataBefore,
                  isEmpty(localesDataAfter) ? null : localesDataAfter,
                  `translations/${localeKey}`,
                ),
              })
            }
          })
        }
      }
    }
  }

  export default toNative(ChangesDialog)
</script>

<style lang="scss" scoped>
  :deep(.changes) {
    tr.v-data-table__tr {
      td:first-child {
        display: none !important;
      }
    }

    thead {
      tr {
        th:first-child {
          display: none !important;
        }
      }
    }
  }
</style>
