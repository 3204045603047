<!-- eslint-disable vue/v-on-handler-style -->
<template>
  <v-timeline-item
    value="1"
    width="100%"
    :dot-color="dotColor"
    :fill-dot="nextStep === ReleaseStepStates.PRIORITY_GROUP_TESTING"
  >
    <template #icon>
      <v-icon class="d-flex flex-start">
        {{ !isLoading && !technicalStatusPass ? 'mdi-alert' : '' }}
      </v-icon>
    </template>

    <h2>Technical status</h2>

    <p v-if="!hasTests" class="ml-2 mt-1">No tests</p>

    <template v-else>
      <v-row v-for="(test, index) in buildTests" :key="index" dense>
        <v-col class="d-flex align-center justify-space-between flex-wrap">
          <p class="d-flex flex-shrink-0">{{ test.testRunType }}</p>
          <p class="d-flex flex-shrink-0 font-weight-bold">
            <v-icon class="mr-2" :icon="releaseTestIcon([test])" :color="releaseTestIconColor([test])" />
            {{ test.testRunOutcome }}
          </p>
        </v-col>

        <v-col class="d-flex align-center justify-end">
          <v-tooltip>
            <template #activator="{ props }">
              <v-chip
                link
                v-bind="props"
                variant="text"
                target="_blank"
                rel="noopener noreferrer"
                :href="test.testRunUrl"
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-chip>
            </template>

            <span>View Github workflow summary</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>

    <v-row dense>
      <v-col class="d-flex align-center justify-space-between flex-wrap">
        <p class="d-flex flex-shrink-0">Session crash free rate</p>

        <v-progress-circular v-if="isLoading" indeterminate />

        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <p v-if="!isLoading" v-bind="props" class="d-flex flex-shrink-0 font-weight-bold">
              {{ sentryCrashFreeRate }}
            </p>
          </template>

          <span>
            Session crash free rate is the percentage of sessions that did not crash. A rate of 99.5 % or higher is
            considered acceptable.
          </span>
        </v-tooltip>
      </v-col>

      <v-spacer />

      <v-col class="d-flex align-center justify-end">
        <v-tooltip>
          <template #activator="{ props }">
            <v-chip link v-bind="props" variant="text" target="_blank" rel="noopener noreferrer" :href="sentryLink">
              <v-icon>mdi-open-in-new</v-icon>
            </v-chip>
          </template>

          <span>View Sentry dashboard</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="testsHaveFailures">
      <v-col>
        <v-tooltip location="bottom center">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              rounded="1"
              variant="flat"
              color="success"
              text="Approve failed tests"
              prepend-icon="mdi-checkbox-multiple-marked-outline"
              @click="manuallyApproveTests()"
            />
          </template>

          <p class="ma-0">Mark build OK for release despite failed tests.</p>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script lang="ts">
  import {
    getReleaseCrashFreeRateFromSentry,
    releaseTestIcon,
    releaseTestIconColor,
    releaseTestsHaveFailures,
    utcDateAndTime,
  } from './utilities'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { ReleasesStore } from '#stores'

  import { AppBuild, ReleaseStepStates, ReleaseSteps, SentryProjects } from '#types/releases'

  @Component({})
  class StepTechnicalStatus extends Vue {
    @Prop({ required: true }) public nextStep!: number

    @Prop({ required: true }) public selectedBuild!: AppBuild

    public isLoading = false

    public sentryCrashFreeRate: string | undefined

    public readonly releaseTestIcon = releaseTestIcon
    public readonly releaseTestIconColor = releaseTestIconColor

    public readonly ReleaseStepStates = ReleaseStepStates

    private readonly releasesStore = new ReleasesStore()

    public get buildTests() {
      return this.releasesStore.appBuildTests[this.selectedBuild.commit] || []
    }

    public get hasTests() {
      return this.buildTests.length > 0
    }

    public get testsHaveFailures() {
      return !this.hasTests || releaseTestsHaveFailures(this.buildTests)
    }

    public get dotColor() {
      if (this.isLoading) {
        return 'transparent'
      }

      if (!this.technicalStatusPass) {
        return 'orange-darken-2'
      }

      return 'green'
    }

    public get technicalStatusPass() {
      return !this.testsHaveFailures
    }

    public get project() {
      return this.selectedBuild.platform === 'ios' ? SentryProjects.IOS : SentryProjects.ANDROID
    }

    public get sentryLink() {
      const pageStart = utcDateAndTime(this.selectedBuild?.createdAt.toDate())
      const pageEnd = utcDateAndTime(new Date())

      return `https://ouraring-ltd.sentry.io/releases/${this.selectedBuild.version}/?project=${this.project}&pageStart=${pageStart}&pageEnd=${pageEnd}`
    }

    @Watch('selectedBuild', { immediate: true })
    public async selectedBuildChanged() {
      this.isLoading = true

      this.sentryCrashFreeRate = await getReleaseCrashFreeRateFromSentry(this.selectedBuild)

      this.isLoading = false

      this.emitOutput()
    }

    @Emit('output')
    public emitOutput(): Pick<ReleaseSteps, 'technicalStatusIsValid'> {
      return { technicalStatusIsValid: this.technicalStatusPass }
    }

    public async manuallyApproveTests() {
      const { platform, commit } = this.selectedBuild

      this.releasesStore.manuallyApproveFailedTests(platform, commit)
    }
  }

  export default toNative(StepTechnicalStatus)
</script>
