import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { collection, getDocs, getFirestore, query } from 'firebase/firestore'

import { JsonDiff } from '@jouzen/outo-toolkit-vuetify'

import { ChangeSet } from '#types/history'

@Store
export class HistoryStore extends Pinia {
  public getDiff = JsonDiff.prototype.getDiff

  public async changes(path: string, name: string, since?: Date) {
    const dbCollection = await getDocs(query(collection(getFirestore(), path)))

    const docs = sortBy(
      dbCollection.docs.map((doc) => doc.data()),
      'timestamp',
    )
      .filter((d) => !since || new Date(d.timestamp.seconds * 1000).getTime() > since?.getTime())
      .reverse()

    return docs.reduce((results: ChangeSet[], doc) => {
      const { author } = doc.after

      const dataAfter = doc.after
      delete dataAfter.author
      delete dataAfter.updatedAt

      const dataBefore = doc.before
      delete dataBefore.author
      delete dataBefore.updatedAt

      const diff = this.getDiff(dataBefore, dataAfter, name)

      if (diff) {
        results.push({ author, timestamp: doc.timestamp, diff })
      }

      return results
    }, [])
  }
}
